import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import ChooseDelivery from "../Pages/Product/ChooseDelivery.js/ChooseDelivery";
import ChooseDate from "./ChooseDate";

const ChangeDateForCartItem = ({
  isChangeDelivery,
  setIsChangeDelivery,
  cartItemProduct,
  cartItemProductVariation,
  orderId,
  sendDeliveryDateToParent,
}) => {
  return (
    <dialog
      class="modal active"
      role="alertdialog"
      aria-live="assertive"
      aria-modal="true"
      open={isChangeDelivery}
    >
      <div class="modal-content">
        <div class="stMy8ahipKs2XZLONBwa">
          <div class="o4CAVfKzzHgQPq8_Uowv">
            <h1>Change Delivery</h1>
            <svg
              onClick={() => setIsChangeDelivery(false)}
              width="15"
              height="15"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="close-modal"
              role="button"
              aria-label="Close Modal"
              tabindex="0"
            >
              <title>Close Modal</title>
              <path
                d="M18 1L2 17"
                stroke="#78858D"
                stroke-width="2"
                stroke-linecap="round"
              ></path>
              <path
                d="M2 1L18 17"
                stroke="#78858D"
                stroke-width="2"
                stroke-linecap="round"
              ></path>
            </svg>
          </div>
          <div class="WsvkhMR_ixT5RUuUDO2z">
            <div class="FBqbhHoD6LOYcyuSs5By">
              <div
                style={{ fontFamily: "Overlock", fontWeight: "bold" }}
                class="step-header"
              >
                Choose your delivery
              </div>
              <ChooseDate
                unAvailableDates={cartItemProduct?.unavailable_dates}
                setIsChangeDelivery={setIsChangeDelivery}
                orderId={orderId}
                sendDeliveryDateToParent={sendDeliveryDateToParent}
              />
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default ChangeDateForCartItem;
