import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.css"; 

const CustomDatePicker = () => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className="custom-date-picker">
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        inline
      />
    </div>
  );
};

export default CustomDatePicker;
