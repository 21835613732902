import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import IntegrityFlower from "./components/Pages/IntegrityFlower/IntegrityFlower";
import MainHeader from "./components/Pages/IntegrityFlower/navbar/MainHeader";
import Footer from "./components/Footer/Footer";
import CategoryList from "./components/Pages/IntegrityFlower/CategoryList/CategoryList";
import Basket from "./components/Basket/Basket";
import WhyYoullLoveShoppingWithUs from "./components/Pages/IntegrityFlower/LandingPage/WhyYoullLoveShoppingWithUs/WhyYoullLoveShoppingWithUs";
import ContactUs from "./components/Pages/contact-us/ContactUs";
import CategoryFlowers from "./components/Pages/Category/CategoryFlowers/CategoryFlowers";
import CategoryFilter from "./components/Pages/Category/CategoryFilter";
import CategoryRoues from "./CategoryRoues";
import HamperDelivery from "./components/Pages/Category/HamperDelivery/HamperDelivery";
import GreetingsCards from "./components/Pages/Category/GreetingsCards/GreetingsCards";
import Account from "./components/Pages/Account/Account";
import Login from "./components/Pages/Login/Login";
import ProductView from "./components/Pages/Product/ProductView";
import CheckoutView from "./components/Pages/Checkout/CheckoutView";
import CategoryGreetingsCards from "./components/Pages/Category/CategoryGreetingsCards/CategoryGreetingsCards";
import AboutUs from "./components/Pages/AboutUs/AboutUs";
import Delivery from "./components/Pages/Delivery/Delivery";
import PosyPoints from "./components/Pages/PosyPoints/PosyPoints";
import ReferAFriend from "./components/Pages/ReferAFriend/ReferAFriend";
import FAQ from "./components/Pages/FAQ/FAQ";
import Terms from "./components/Pages/Terms/Terms";
import Affiliates from "./components/Pages/Affiliates/Affiliates";
import { allCategories, allProducts } from "./api/api";
import {
  addCategory,
  addCategoryWithParentValueNull,
} from "./features/Category/CategorySlice";
import { useDispatch } from "react-redux";
import { addProducts } from "./features/Products/ProductSlice";
import WishList from "./components/WishList/WishList";
import Cookies from "js-cookie";
import { addToken, addUser } from "./features/User/userSlice";
import Register from "./components/Pages/Register/Register";
import SubCategoryFlowers from "./components/Pages/Category/SubCategoryFlowers/SubCategoryFlowers";
import Search from "./components/Pages/search/Search";
import OrderHistory from "./components/Pages/OrderHistory/OrderHistory";
import ChangePassword from "./components/Pages/ChangePassword/ChangePassword";
import EditDetails from "./components/Pages/EditDetails/EditDetails";
import OrderDetails from "./components/Pages/OrderDetails/OrderDetails";
import { addIsCheckOut } from "./features/checkout/checkOutSlice";
import { useLocation } from "react-router-dom";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import CookiesPolicy from "./components/Cookies/Cookies";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import ForgotPassword from "./components/Pages/ForgotPassword/ForgotPassword";
import ForgotPassword2 from "./components/Pages/ForgotPassword/ForgotPassword2";
import "./index.css";
import "./App.css";
import DatePickerComponent from "./components/Pages/CostomDatePicker/CustomDatePicker";

function App() {
  const dispatch = useDispatch();
  const [category, setCategory] = useState(null);

  const user = localStorage.getItem("user");
  const token = Cookies.get("token");

  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    const isCheckoutIncluded = path.includes("checkout");
    if (isCheckoutIncluded) {
      dispatch(addIsCheckOut(true));
    } else {
      dispatch(addIsCheckOut(false));
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (!token || !user) return;
    dispatch(addUser(user));
    dispatch(addToken(token));
  }, [user, token]);

  useEffect(() => {
    loadCategory();
    loadProduct();
  }, []);

  const loadProduct = async () => {
    try {
      const response = await allProducts();
      dispatch(addProducts(response?.data?.data));
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadCategory = async () => {
    try {
      const response = await allCategories();
      const categoriesData = response?.data?.data || [];
      setCategory(categoriesData);
      dispatch(addCategory(categoriesData));
      const catWithNullParent = categoriesData.filter((cat) => {
        return cat.parent_category === null;
      });
      dispatch(addCategoryWithParentValueNull(catWithNullParent));
    } catch (error) {
      console.log("error", error);
    }
  };

  const Layout = () => (
    <>
      <div className="full-height">
        <div className="bn-header-container">
          {category && (
            <div className="bn-header-wrapper">
              <MainHeader />
              <CategoryList />
              {/* <WishList /> */}
            </div>
          )}
        </div>
      </div>
      {category && (
        <Routes>
          <Route path="/" element={<IntegrityFlower />} />

          <Route path="/account" element={<Account />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          <Route path="/hamper-delivery" element={<HamperDelivery />} />
          <Route path="/greetings-cards" element={<GreetingsCards />} />

          <Route path="/product/:id" element={<ProductView />} />

          <Route path="/search/:slug" element={<Search />} />

          <Route path="/category/flowers/:id" element={<CategoryFlowers />} />
          <Route path="/date-picker" element={<DatePickerComponent />} />
          <Route
            path="/sub-category/flowers/:id"
            element={<SubCategoryFlowers />}
          />
          <Route path="/category/hand-tied" element={<CategoryFlowers />} />
          <Route path="/category/house-plants" element={<CategoryFlowers />} />
          <Route path="/category/hampers" element={<CategoryFlowers />} />
          <Route
            path="/category/letterbox-flowers"
            element={<CategoryFlowers />}
          />
          <Route
            path="/category/birthday-flowers-and-gifts"
            element={<CategoryFlowers />}
          />
          <Route
            path="/category/greetings-cards"
            element={<CategoryGreetingsCards />}
          />

          <Route path="/account/order-history" element={<OrderHistory />} />
          <Route path="/account/order-details/:id" element={<OrderDetails />} />
          <Route path="/account/change-password" element={<ChangePassword />} />
          <Route path="/account/edit-details" element={<EditDetails />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/posy-points" element={<PosyPoints />} />
          <Route path="/refer-a-friend" element={<ReferAFriend />} />
          {/* <Route path="/faq" element={<FAQ />} /> */}
          <Route path="/terms" element={<Terms />} />
          <Route path="/affiliates" element={<Affiliates />} />
          <Route path="/cookie-policy" element={<CookiesPolicy />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/forgot-password/:slug" element={<ForgotPassword2 />} />
        </Routes>
      )}

      {category && (
        <>
          <WhyYoullLoveShoppingWithUs />
          <Footer />
        </>
      )}
    </>
  );

  return (
    <main>
      <Routes>
        <Route path="/checkout/:id" element={<CheckoutView />} />
        <Route path="/*" element={<Layout />} />
      </Routes>
      <Basket />
    </main>
  );
}

export default App;
