import React from "react";

const HamperDelivery = () => {
  return (
    <main id="main-container" class="container top-margin fluid-container">
      <div class="nav-blackout"></div>
      <div class="landing-page">
        <section class="why-choose-bunches" aria-label="Why Choose Bunches">
          <div class="content background-brown">
            <div class="icon" aria-label="Previous" role="button">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                <path
                  d="M18 33C26.2845 33 33 26.2845 33 18C33 9.7155 26.2845 3 18 3C9.7155 3 3 9.7155 3 18C3 26.2845 9.7155 33 18 33Z"
                  stroke="white"
                  strokeWidth="1"
                  stroke-miterlimit="10"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M19.8899 23.295L14.6099 18L19.8899 12.705"
                  stroke="white"
                  strokeWidth="1"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
            <div class="slider">
              <ul class="items" style={{ transform: "translateX(-100%)" }}>
                <li class="item" style={{ order: "0" }}>
                  <p>EARN POSY POINTS</p>
                  Save money on future orders
                </li>
                <li class="item" style={{ order: "1" }}>
                  <p>10% OF PROFITS TO CHARITY</p>
                  Your gift keeps on giving
                </li>
                <li class="item" style={{ order: "2" }}>
                  <p>A FAMILY BUSINESS SINCE 1989</p>
                  More than 30 years of experience
                </li>
                <li class="item" style={{ order: "-1" }}>
                  <p>WE DELIVER HAPPINESS</p>
                  More than 10 million gifts delivered
                </li>
              </ul>
            </div>
            <div class="icon" role="button" aria-label="Next">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
                <path
                  d="M18 33C26.2845 33 33 26.2845 33 18C33 9.7155 26.2845 3 18 3C9.7155 3 3 9.7155 3 18C3 26.2845 9.7155 33 18 33Z"
                  stroke="white"
                  strokeWidth="1"
                  stroke-miterlimit="10"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M19.8899 23.295L14.6099 18L19.8899 12.705"
                  stroke="white"
                  strokeWidth="1"
                  strokeLinecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
        </section>
        <section
          class="five-product-bestseller"
          aria-label="Five Best Selling Products"
        >
          <div class="column-one">
            <div class="product-container pick-of-the-day">
              <a href="/product/three-cheese-hamper">
                <img
                  class="img-fluid"
                  src="https://media.bunches.co.uk/products/h23137-featured.jpg"
                  alt="Three Cheese Hamper"
                  width="540"
                  height="632"
                  loading="lazy"
                />
              </a>
              <div class="product-details">
                <div class="three">
                  <a class="name" href="/product/three-cheese-hamper" >
                    Three Cheese Hamper
                  </a>
                  <a class="description" href="/product/three-cheese-hamper">
                    Three 100g cheese truckles are complemented by spicy
                    chutney, crackers and oatcake bites.
                  </a>
                </div>
                <a class="price" href="/product/three-cheese-hamper">
                  £21.95
                </a>
                <div class="favourite-button-container">
                  <span class="favourite-button">
                    <span class="background">
                      <i
                        class="fa fa-heart-o heart"
                        role="button"
                        aria-label="Add to Favourites"
                      ></i>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="column-two">
            <div class="product-container">
              <a href="/product/afternoon-tea-hamper">
                <img
                  class="img-fluid"
                  src="https://media.bunches.co.uk/products/586x586/h23118-category.jpg"
                  alt="Afternoon Tea Hamper"
                  width="255"
                  height="255"
                  loading="lazy"
                />
              </a>
              <div class="favourite-button-container">
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <div class="product-details">
                <a class="name" href="/product/afternoon-tea-hamper">
                  Afternoon Tea Hamper
                </a>
                <a class="price" href="/product/afternoon-tea-hamper">
                  £20.35
                </a>
              </div>
            </div>
            <div class="product-container">
              <a href="/product/tea-and-sweet-treats">
                <img
                  class="img-fluid"
                  src="https://media.bunches.co.uk/products/586x586/hgutea-category.jpg"
                  alt="Tea and Sweet Treats"
                  width="255"
                  height="255"
                  loading="lazy"
                />
              </a>
              <div class="favourite-button-container">
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <div class="product-details">
                <a class="name" href="/product/tea-and-sweet-treats">
                  Tea and Sweet Treats
                </a>
                <a class="price" href="/product/tea-and-sweet-treats">
                  £26.50
                </a>
              </div>
            </div>
            <div class="product-container">
              <a href="/product/love-chocolate-gift-bag">
                <img
                  class="img-fluid"
                  src="https://media.bunches.co.uk/products/586x586/h82295-category.jpg"
                  alt="Love Chocolate Gift Bag"
                  width="255"
                  height="255"
                  loading="lazy"
                />
              </a>
              <div class="favourite-button-container">
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <div class="product-details">
                <a class="name" href="/product/love-chocolate-gift-bag">
                  Love Chocolate Gift Bag
                </a>
                <a class="price" href="/product/love-chocolate-gift-bag">
                  £24.65
                </a>
              </div>
            </div>
            <div class="product-container">
              <a href="/product/thank-you-gift-hamper">
                <img
                  class="img-fluid"
                  src="https://media.bunches.co.uk/products/586x586/hgtbot-category.jpg"
                  alt="Thank You Gift Hamper"
                  width="255"
                  height="255"
                  loading="lazy"
                />
              </a>
              <div class="favourite-button-container">
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <div class="product-details">
                <a class="name" href="/product/thank-you-gift-hamper">
                  Thank You Gift Hamper
                </a>
                <a class="price" href="/product/thank-you-gift-hamper">
                  £31.95
                </a>
              </div>
            </div>
          </div>
        </section>
        <section class="review-module" aria-label="Reviews">
          <div class="content">
            <div class="row">
              <div class="review-module__section col-6 col-md-3 col-lg-2">
                <span class="review-mobile__rating">Excellent</span>
                <div>
                  <span
                    class="review-module__star"
                    role="img"
                    aria-label="Star"
                  ></span>
                  <span
                    class="review-module__star"
                    role="img"
                    aria-label="Star"
                  ></span>
                  <span
                    class="review-module__star"
                    role="img"
                    aria-label="Star"
                  ></span>
                  <span
                    class="review-module__star"
                    role="img"
                    aria-label="Star"
                  ></span>
                  <span
                    class="review-module__star"
                    role="img"
                    aria-label="Star"
                  ></span>
                </div>
              </div>
              <div class="review-module__section col-6 col-md-3 col-lg-2">
                <div class="reviewscouk_score">4.66 Average</div>
                <div class="reviewscouk_count">188,703 Reviews</div>
              </div>
              <div class="review-module__section col-12 col-md-6 hidden-sm-down">
                <div class="review-module__row">
                  <h3 class="review-author">Anonymous</h3>
                  <div>
                    <span class="review-timestamp">1 hour ago</span>
                    <span>
                      <span
                        class="review-module__star"
                        role="img"
                        aria-label="Star"
                      ></span>
                      <span
                        class="review-module__star"
                        role="img"
                        aria-label="Star"
                      ></span>
                      <span
                        class="review-module__star"
                        role="img"
                        aria-label="Star"
                      ></span>
                      <span
                        class="review-module__star"
                        role="img"
                        aria-label="Star"
                      ></span>
                      <span
                        class="review-module__star"
                        role="img"
                        aria-label="Star"
                      ></span>
                    </span>
                  </div>
                </div>
                <div class="reviews-module-review">
                  <p>My sister was delighted with her 💐</p>
                </div>
              </div>
              <div class="review-module__section col-12 col-lg-2">
                <div class="row reviews-module--website">
                  <div class="col-6 col-md-12">
                    <img
                      class="img-fluid"
                      src="https://media.bunches.co.uk/reviews_logo_new.png"
                      alt="Image of Reviews.co.uk logo"
                      loading="lazy"
                      height="23"
                      width="133"
                    />
                  </div>
                  <div class="col-6 col-md-12 hidden-sm-down">
                    <a
                      href="https://bunchescouk.reviews.co.uk/"
                      class="bn-button bn-button--primary bn-button--full"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View More
                    </a>
                  </div>
                  <div class="col-6 col-md-12 hidden-md-up">
                    <a
                      href="https://bunchescouk.reviews.co.uk/"
                      class="bn-button bn-button--primary bn-button--reviews"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Reviews
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="homepage-carousel" aria-label="Birthday Hampers">
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/category/birthday-hampers">Birthday Hampers</a>
              <a class="view-all-headerlink" href="/category/birthday-hampers">
                View all
              </a>
            </h2>
          </header>
          <div class="items has-three">
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/birthday-sweet-treats"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hghb1-category.jpg"
                    alt="Birthday Sweet Treats"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/birthday-sweet-treats"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Birthday Sweet Treats</h3>
                  <p class="price">£25.75</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/bubbles-and-birthday-treats"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hgblux-category.jpg"
                    alt="Bubbles and Birthday Treats"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/bubbles-and-birthday-treats"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Bubbles and Birthday Treats</h3>
                  <p class="price">£35</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/happy-birthday-with-fizz"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h24204-category.jpg"
                    alt="Happy Birthday With Fizz"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/happy-birthday-with-fizz"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Happy Birthday With Fizz</h3>
                  <p class="price">£28.55</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/birthday-bliss"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h83104-category.jpg"
                    alt="Birthday Bliss"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/birthday-bliss"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Birthday Bliss</h3>
                  <p class="price">£29.75</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/the-birthday-box"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h83095-category.jpg"
                    alt="The Birthday Box"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/the-birthday-box"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">The Birthday Box</h3>
                  <p class="price">£29.77</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/happy-birthday-chocolate-hamper"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hghbcx-category.jpg"
                    alt="Happy Birthday Chocolate Hamper"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/happy-birthday-chocolate-hamper"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Happy Birthday Chocolate Hamper</h3>
                  <p class="price">£24.75</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/happy-birthday-letterbox-gift"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hp22045-category.jpg"
                    alt="Happy Birthday Letterbox Gift"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/happy-birthday-letterbox-gift"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Happy Birthday Letterbox Gift</h3>
                  <p class="price">£28.45</p>
                </div>
              </a>
            </div>
          </div>
        </section>
        <section class="homepage-carousel" aria-label="Cheese Hampers">
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/category/cheese-hampers">Cheese Hampers</a>
              <a class="view-all-headerlink" href="/category/cheese-hampers">
                View all
              </a>
            </h2>
          </header>
          <div class="items">
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/the-cheese-cool-bag"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h23063-category.jpg"
                    alt="The Cheese Cool Bag"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/the-cheese-cool-bag"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">The Cheese Cool Bag</h3>
                  <p class="price">£23.95</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/cheese-and-wine-slate"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h82304-category.jpg"
                    alt="Cheese and Wine Slate"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/cheese-and-wine-slate"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Cheese and Wine Slate</h3>
                  <p class="price">£39.97</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/the-big-cheese-box"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h82673-category.jpg"
                    alt="The Big Cheese Box"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/the-big-cheese-box"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">The Big Cheese Box</h3>
                  <p class="price">£28.89</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/cider-and-cheese-gift"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h82680-category.jpg"
                    alt="Cider and Cheese Gift"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/cider-and-cheese-gift"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Cider and Cheese Gift</h3>
                  <p class="price">£45.20</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/three-cheese-hamper"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h23137-category.jpg"
                    alt="Three Cheese Hamper"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/three-cheese-hamper"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Three Cheese Hamper</h3>
                  <p class="price">£21.95</p>
                </div>
              </a>
            </div>
          </div>
        </section>
        <section class="homepage-carousel" aria-label="Chocolate Hampers">
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/category/chocolate-hampers">Chocolate Hampers</a>
              <a class="view-all-headerlink" href="/category/chocolate-hampers">
                View all
              </a>
            </h2>
          </header>
          <div class="items">
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/love-chocolate-gift-bag"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h82295-category.jpg"
                    alt="Love Chocolate Gift Bag"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/love-chocolate-gift-bag"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Love Chocolate Gift Bag</h3>
                  <p class="price">£24.65</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/chocolate-lovers-treat-box"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hgchoc-category.jpg"
                    alt="Chocolate Lovers Treat Box"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/chocolate-lovers-treat-box"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Chocolate Lovers Treat Box</h3>
                  <p class="price">£24</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/chocolate-treats-hamper"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h23017-category.jpg"
                    alt="Chocolate Treats Hamper"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/chocolate-treats-hamper"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Chocolate Treats Hamper</h3>
                  <p class="price">£39.17</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/chocolate-indulgence"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h82287-category.jpg"
                    alt="Chocolate Indulgence "
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/chocolate-indulgence"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Chocolate Indulgence</h3>
                  <p class="price">£39.95</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/chocolate-tower"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h23132-category.jpg"
                    alt="Chocolate Tower"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/chocolate-tower"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Chocolate Tower</h3>
                  <p class="price">£48.65</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/the-chocoholics-letterbox-gift"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h82849-category.jpg"
                    alt="The Chocoholics Letterbox Gift"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/the-chocoholics-letterbox-gift"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">The Chocoholics Letterbox Gift</h3>
                  <p class="price">£20.65</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/chocolate-lovers-letterbox-gift"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hp22003-category.jpg"
                    alt="Chocolate Lovers Letterbox Gift"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/chocolate-lovers-letterbox-gift"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Chocolate Lovers Letterbox Gift</h3>
                  <p class="price">£19.45</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/happy-birthday-chocolate-hamper"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hghbcx-category.jpg"
                    alt="Happy Birthday Chocolate Hamper"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/happy-birthday-chocolate-hamper"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Happy Birthday Chocolate Hamper</h3>
                  <p class="price">£24.75</p>
                </div>
              </a>
            </div>
          </div>
        </section>
        <section
          class="homepage-carousel"
          aria-label="Tea &amp; Coffee Hampers"
        >
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/category/tea-and-coffee-hampers">
                Tea &amp; Coffee Hampers
              </a>
              <a
                class="view-all-headerlink"
                href="/category/tea-and-coffee-hampers"
              >
                View all
              </a>
            </h2>
          </header>
          <div class="items">
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/luxury-cafetiere-and-treats-box"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hgclux-category.jpg"
                    alt="Luxury Cafetiere and Treats Box"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/luxury-cafetiere-and-treats-box"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Luxury Cafetiere and Treats Box</h3>
                  <p class="price">£30</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/tea-and-bubbles"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h82300-category.jpg"
                    alt="Tea and Bubbles"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/tea-and-bubbles"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Tea and Bubbles</h3>
                  <p class="price">£34.95</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/coffee-break"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h24212-category.jpg"
                    alt="Coffee Break"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a href="/product/coffee-break" style={{ pointerEvents: "all" }}>
                <div class="content-holder">
                  <h3 class="title">Coffee Break</h3>
                  <p class="price">£30.75</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/coffee-and-cake"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h83950-category.jpg"
                    alt="Coffee &amp; Cake"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/coffee-and-cake"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Coffee &amp; Cake</h3>
                  <p class="price">£44.99</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/tea-and-sweet-treats"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hgutea-category.jpg"
                    alt="Tea and Sweet Treats"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/tea-and-sweet-treats"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Tea and Sweet Treats</h3>
                  <p class="price">£26.50</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/afternoon-tea-letterbox"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hp22001-category.jpg"
                    alt="Afternoon Tea Letterbox Gift"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/afternoon-tea-letterbox"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Afternoon Tea Letterbox Gift</h3>
                  <p class="price">£16.95</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/made-with-love"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h24206-category.jpg"
                    alt="Made with Love"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/made-with-love"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Made with Love</h3>
                  <p class="price">£26.95</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/afternoon-tea-hamper"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h23118-category.jpg"
                    alt="Afternoon Tea Hamper"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/afternoon-tea-hamper"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Afternoon Tea Hamper</h3>
                  <p class="price">£20.35</p>
                </div>
              </a>
            </div>
          </div>
        </section>
        <section
          class="homepage-carousel"
          aria-label="Letterbox Friendly Gifts"
        >
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/category/letterbox-friendly-gifts">
                Letterbox Friendly Gifts
              </a>
              <a
                class="view-all-headerlink"
                href="/category/letterbox-friendly-gifts"
              >
                View all
              </a>
            </h2>
          </header>
          <div class="items">
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/delectable-delights-letterbox-gift"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h82847-category.jpg"
                    alt="Delectable Delights Letterbox Gift"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/delectable-delights-letterbox-gift"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Delectable Delights Letterbox Gift</h3>
                  <p class="price">£20.70</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/the-chocoholics-letterbox-gift"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h82849-category.jpg"
                    alt="The Chocoholics Letterbox Gift"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/the-chocoholics-letterbox-gift"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">The Chocoholics Letterbox Gift</h3>
                  <p class="price">£20.65</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/afternoon-tea-letterbox"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hp22001-category.jpg"
                    alt="Afternoon Tea Letterbox Gift"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/afternoon-tea-letterbox"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Afternoon Tea Letterbox Gift</h3>
                  <p class="price">£16.95</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/chocolate-lovers-letterbox-gift"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hp22003-category.jpg"
                    alt="Chocolate Lovers Letterbox Gift"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/chocolate-lovers-letterbox-gift"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Chocolate Lovers Letterbox Gift</h3>
                  <p class="price">£19.45</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/girlie-letterbox-gift"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hp22005-category.jpg"
                    alt="Girlie Letterbox Gift"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/girlie-letterbox-gift"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Girlie Letterbox Gift</h3>
                  <p class="price">£18.95</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/vegan-goodies-letterbox-gift"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hp22046-category.jpg"
                    alt="Vegan Goodies Letterbox Gift"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/vegan-goodies-letterbox-gift"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Vegan Goodies Letterbox Gift</h3>
                  <p class="price">£18.95</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/happy-birthday-letterbox-gift"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hp22045-category.jpg"
                    alt="Happy Birthday Letterbox Gift"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/happy-birthday-letterbox-gift"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Happy Birthday Letterbox Gift</h3>
                  <p class="price">£28.45</p>
                </div>
              </a>
            </div>
          </div>
        </section>
        <section class="homepage-carousel" aria-label="Wine Gifts">
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/category/wine-gifts">Wine Gifts</a>
              <a class="view-all-headerlink" href="/category/wine-gifts">
                View all
              </a>
            </h2>
          </header>
          <div class="items">
            <div class="item">
              <div class="favourite-button-container">
                <a href="/product/wine-duet" style={{ pointerEvents: "all" }}>
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h83714-category.jpg"
                    alt="Wine Duet"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a href="/product/wine-duet" style={{ pointerEvents: "all" }}>
                <div class="content-holder">
                  <h3 class="title">Wine Duet</h3>
                  <p class="price">£29.75</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/monteran-classic-french-wine-duo"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h83887-category.jpg"
                    alt="Monteran Classic French Wine Duo"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/monteran-classic-french-wine-duo"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Monteran Classic French Wine Duo</h3>
                  <p class="price">£32.45</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/four-wines-in-a-box"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h83711-category.jpg"
                    alt="Four Wines in a Box"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/four-wines-in-a-box"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Four Wines in a Box</h3>
                  <p class="price">£51.25</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/wine-basket-gift"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h83444-category.jpg"
                    alt="Wine Basket Gift"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/wine-basket-gift"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Wine Basket Gift</h3>
                  <p class="price">£71.49</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/italian-wine-duo"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h82280-category.jpg"
                    alt="Italian Wine Duo"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/italian-wine-duo"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Italian Wine Duo</h3>
                  <p class="price">£29.85</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/red-and-white-wine-selection"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h82268-category.jpg"
                    alt="Red &amp; White Wine Selection"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/red-and-white-wine-selection"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Red &amp; White Wine Selection</h3>
                  <p class="price">£71.45</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/wine-and-accessories-gift-box"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h23252-category.jpg"
                    alt="Wine &amp; Accessories Gift Box"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/wine-and-accessories-gift-box"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Wine &amp; Accessories Gift Box</h3>
                  <p class="price">£31.25</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/red-wine-of-the-world"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h23154-category.jpg"
                    alt="Red Wine of the World"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/red-wine-of-the-world"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Red Wine of the World</h3>
                  <p class="price">£25.70</p>
                </div>
              </a>
            </div>
          </div>
        </section>
        <section class="homepage-carousel" aria-label="Speciality Diet Hampers">
          <header class="lined-header" aria-label="header">
            <h2>
              <a href="/category/speciality-diet-hampers">
                Speciality Diet Hampers
              </a>
              <a
                class="view-all-headerlink"
                href="/category/speciality-diet-hampers"
              >
                View all
              </a>
            </h2>
          </header>
          <div class="items">
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/gluten-and-wheat-free-gift-bag"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h82672-category.jpg"
                    alt="Gluten and Wheat Free Gift Bag"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/gluten-and-wheat-free-gift-bag"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Gluten and Wheat Free Gift Bag</h3>
                  <p class="price">£34.95</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/the-vegan-basket"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h82676-category.jpg"
                    alt="The Vegan Basket"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/the-vegan-basket"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">The Vegan Basket</h3>
                  <p class="price">£34.90</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/gluten-free-and-vegan-hamper"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h23073-category.jpg"
                    alt="Gluten Free And Vegan Hamper"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/gluten-free-and-vegan-hamper"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Gluten Free And Vegan Hamper</h3>
                  <p class="price">£37.45</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/the-vegan-treats-basket"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h83102-category.jpg"
                    alt="The Vegan Treats Basket"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/the-vegan-treats-basket"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">The Vegan Treats Basket</h3>
                  <p class="price">£44.99</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/vegan-goodies-letterbox-gift"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/hp22046-category.jpg"
                    alt="Vegan Goodies Letterbox Gift"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/vegan-goodies-letterbox-gift"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Vegan Goodies Letterbox Gift</h3>
                  <p class="price">£18.95</p>
                </div>
              </a>
            </div>
            <div class="item">
              <div class="favourite-button-container">
                <a
                  href="/product/low-sugar-gift-basket"
                  style={{ pointerEvents: "all" }}
                >
                  <img
                    class="img-fluid"
                    src="https://media.bunches.co.uk/products/586x586/h83035-category.jpg"
                    alt="Low Sugar Gift Basket"
                    width="285"
                    height="285"
                    loading="lazy"
                  />
                </a>
                <span class="favourite-button">
                  <span class="background">
                    <i
                      class="fa fa-heart-o heart"
                      role="button"
                      aria-label="Add to Favourites"
                    ></i>
                  </span>
                </span>
              </div>
              <a
                href="/product/low-sugar-gift-basket"
                style={{ pointerEvents: "all" }}
              >
                <div class="content-holder">
                  <h3 class="title">Low Sugar Gift Basket</h3>
                  <p class="price">£39.97</p>
                </div>
              </a>
            </div>
          </div>
        </section>
      </div>
      <section class="email-newsletter">
        <div class="header">
          <h2>
            <img
              class="img-fluid icon"
              src="https://media.bunches.co.uk/icons/icon_envelope.svg"
              alt="Icon of an envelope representing our newsletter email."
              width="28"
              height="20"
              loading="lazy"
            />
            Keep in touch with us
          </h2>
          <p>
            Sign up to our emails for news, competitions and exclusive
            discounts.
          </p>
        </div>
        <form class="form">
          <input
            type="text"
            class="input--full"
            placeholder="Your name"
            aria-label="Your full name"
            required=""
            value=""
          />
          <input
            type="email"
            class="input--full"
            placeholder="Your email address"
            aria-label="The email address you'd like to receive the newsletter to"
            required=""
            value=""
          />
          <button class="bn-button bn-button--primary" type="submit">
            Subscribe
          </button>
        </form>
        <section class="notification-section"></section>
        <div class="small-print">
          <p>
            We won't share your details with anyone. View our
            <a href="/terms#privacyPolicy">Privacy Policy</a>.
          </p>
        </div>
      </section>
    </main>
  );
};

export default HamperDelivery;
