import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { category_slider } from "../../../../../../api/api";
import { useNavigate } from "react-router-dom";
import "./CategorySlider.css";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const ImageSlider = () => {
  const navigate = useNavigate();
  const [sliderData, setSliderData] = useState([]);
  useEffect(() => {
    const loadSliderData = async () => {
      try {
        const response = await category_slider();
        setSliderData(response.data.data);
      } catch (error) {
        console.log("error *********", error);
      }
    };
    loadSliderData();
  }, []);

  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={1}
      navigation={true}
      pagination={{ clickable: true }}
      autoplay={{ delay: 2500, disableOnInteraction: false }}
      loop={true}
      modules={[Navigation, Pagination, Autoplay]}
    >
      {sliderData.map((slide) => (
        <SwiperSlide key={slide.id}>
          <img
            onClick={() => {
              navigate(`/category/flowers/${slide.category_id}`);
            }}
            src={imageUrl + slide.banner_image}
            alt={slide.alt}
            style={{ width: "100%", height: "500px" }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ImageSlider;
