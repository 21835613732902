import React, { useEffect, useState } from "react";
import { changePassword } from "../../../api/api";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const ChangePassword = () => {
  const navigate = useNavigate();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [passwordError, setPasswordError] = useState({
    currentPassword: "",
    newPassword: "",
  });

  useEffect(() => {
    const isLogin = () => {
      const token = Cookies.get("token");
      if (!token) {
        navigate("/login");
      }
    };
    isLogin();
  }, []);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    let newErrors = {};

    if (!currentPassword) {
      newErrors = {
        ...newErrors,
        currentPassword: "Please enter your old Password.",
      };
    }

    if (!newPassword) {
      newErrors = {
        ...newErrors,
        newPassword: "Please enter your new Password.",
      };
    } else if (newPassword.length < 10) {
      newErrors = {
        ...newErrors,
        newPassword:
          "Your new password needs to be a minimum of 10 characters.",
      };
    }

    setPasswordError((prevErrors) => ({
      ...prevErrors,
      ...newErrors,
    }));

    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await changePassword({
          old_password: currentPassword,
          new_password: newPassword,
          new_password_confirmation: newPassword,
        });
        if (response?.data?.message === "Password updated successfully") {
          navigate("/");
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <div class="change-password">
        <div class="row">
          <div class="col-12">
            <ul class="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/account">Your Account</Link>
              </li>
              <li>Change Your Password</li>
            </ul>
          </div>
        </div>
        <div
          style={{ fontFamily: "Overlock", fontWeight: "bold" }}
          class="header-title"
        >
          Change Password
        </div>
        <form class="form">
          <div class="form-group">
            <label
              style={{ fontFamily: "Overlock", fontWeight: "bold" }}
              for="oldPassword"
              class="bn-label"
            >
              Current Password
            </label>
            <input
              id="oldPassword"
              name="oldPassword"
              type="password"
              autocomplete=""
              class="form-control"
              placeholder=""
              required=""
              minlength="1"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <span
              role="button"
              aria-label="Show Password"
              class="fa password-view-icon fa-eye"
            ></span>
            {passwordError.currentPassword && (
              <div style={{ color: "red" }} className="error">
                {passwordError.currentPassword}
              </div>
            )}
          </div>
          <div class="form-group">
            <label
              style={{ fontFamily: "Overlock", fontWeight: "bold" }}
              for="newPassword"
              class="bn-label"
            >
              New Password
            </label>
            <input
              id="newPassword"
              name="newPassword"
              type="password"
              autocomplete=""
              class="form-control"
              placeholder=""
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <span
              role="button"
              aria-label="Show Password"
              class="fa password-view-icon fa-eye"
            ></span>
            {passwordError.newPassword && (
              <div style={{ color: "red" }} className="error">
                {passwordError.newPassword}
              </div>
            )}
          </div>
          <div class="text-center">
            <button
              onClick={handleChangePassword}
              type="submit"
              class="bn-button bn-button--primary"
            >
              Change Password
            </button>
          </div>
        </form>
        <div class="password-tips">
          <h3 class="title">Password Tips</h3>
          <ul class="items">
            <li>
              <strong style={{ fontFamily: "Overlock", fontWeight: "bold" }}>
                Make it difficult to guess
              </strong>
              <br />
              Avoid using names, common words or dates that are personal to you,
              it might make it easy for someone else to guess.
            </li>
            <li>
              <strong style={{ fontFamily: "Overlock", fontWeight: "bold" }}>
                Not too short
              </strong>
              <br />
              Whilst we ask for your password to be a minimum of 10 characters,
              the longer it is the harder it will be for someone to guess.
            </li>
            <li>
              <strong style={{ fontFamily: "Overlock", fontWeight: "bold" }}>
                Don't repeat yourself
              </strong>
              <br />
              Try to use a different password for all your online accounts and
              make sure your Bunches password isn't one you already use
              elsewhere.
            </li>
            <li>
              <strong style={{ fontFamily: "Overlock", fontWeight: "bold" }}>
                Mix it up
              </strong>
              <br />
              Use a combination of letters, numbers, lowercase and uppercase to
              add an extra level of security.
            </li>
          </ul>
        </div>
      </div>
    </main>
  );
};

export default ChangePassword;
