import React, { useEffect, useState } from "react";
import { change_recipient_info_by_order_id } from "../../../../api/api";
import Loader from "../../../Loader/Loader";

const ChangeRecipient = ({
  openModal,
  closeModal,
  orderDetails,
  productId,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [orderDetailsToShow, setOrderDetailsToShow] = useState();
  const [formData, setFormData] = useState({
    name: "",
    address_line_1: "",
    address_line_2: "",
    postcode: "",
    city: "",
    country: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    address_line_1: "",
    address_line_2: "",
    postcode: "",
    city: "",
    country: "",
  });

  useEffect(() => {
    if (!orderDetails) return;
    setOrderDetailsToShow(orderDetails);
    setFormData({
      ...formData,
      name: orderDetails.recipient_name,
      address_line_1: orderDetails.address_line_1,
      address_line_2: orderDetails.address_line_2,
      postcode: orderDetails.postal_code,
      city: orderDetails.city,
      country: orderDetails.country,
    });
  }, [orderDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = {};

    if (!formData.name) {
      newErrors = {
        ...newErrors,
        name: "Please enter full name",
      };
    }

    if (!formData.postcode) {
      newErrors = {
        ...newErrors,
        postcode: "Please enter postcode.",
      };
    }

    if (!formData.address_line_1) {
      newErrors = {
        ...newErrors,
        address_line_1: "Please enter address line 1",
      };
    }

    if (!formData.address_line_2) {
      newErrors = {
        ...newErrors,
        address_line_2: "Please enter address line 2",
      };
    }

    if (!formData.city) {
      newErrors = {
        ...newErrors,
        city: "Please enter city",
      };
    }

    if (!formData.country) {
      newErrors = {
        ...newErrors,
        country: "Please enter country",
      };
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      ...newErrors,
    }));

    if (Object.keys(newErrors).length === 0) {
      setIsEdit(false);
    }
  };

  const handleRecipientAddressNameChange = async () => {
    setIsLoading(true);
    const formDataTwo = new FormData();
    formDataTwo.append("order_id", productId);
    formDataTwo.append("recipient_name", formData?.name);
    formDataTwo.append("city", formData?.city);
    formDataTwo.append("country", formData?.country);
    formDataTwo.append("address_line_1", formData?.address_line_1);
    formDataTwo.append("address_line_2", formData?.address_line_2);
    formDataTwo.append("postal_code", formData?.postcode);

    try {
      const response = await change_recipient_info_by_order_id(formDataTwo);
      closeModal();
      window.location.reload();
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSaveRecipientClick = () => {
    const formDataTwo = {
      recipient_name: formData?.name,
      city: formData?.city,
      country: formData?.country,
      address_line_1: formData?.address_line_1,
      address_line_2: formData?.address_line_2,
      postal_code: formData?.postcode,
    };

    orderDetails = formDataTwo;
    setOrderDetailsToShow({
      recipient_name: formData?.name,
      city: formData?.city,
      country: formData?.country,
      address_line_1: formData?.address_line_1,
      address_line_2: formData?.address_line_2,
      postal_code: formData?.postcode,
    });
    // orderDetails
  };

  return (
    <dialog
      class="modal active"
      role="alertdialog"
      aria-live="assertive"
      aria-modal="true"
      open={openModal === "Change Recipient"}
    >
      {orderDetails && (
        <div class="modal-content">
          <div class="vaF02FJcvbQm8612Ejv4">
            <div class="f2GfeKHHOe0dBt0lWdpk">
              <h1>Change Recipient</h1>
              <svg
                onClick={() => closeModal()}
                width="15"
                height="15"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="close-modal"
                role="button"
                aria-label="Close Modal"
                tabindex="0"
              >
                <title>Close Modal</title>
                <path
                  d="M18 1L2 17"
                  stroke="#78858D"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M2 1L18 17"
                  stroke="#78858D"
                  stroke-width="2"
                  stroke-linecap="round"
                ></path>
              </svg>
            </div>
            <div class="DLql3EbomW8ienD_YcxM">
              <div class="Tg3Kn7LFXB_J1vgyc2Ml">
                <div>
                  <div class="pcyUpWXdmQo7h9iZkZNr">
                    {!isEdit && (
                      <div class="EKpmUwU1yda7Qzoj_9De">
                        <h4>Delivery Name and Address</h4>
                        <div class="DhjvOuCfpWzZzf8saSzw">
                          <span>{orderDetailsToShow?.recipient_name}</span>
                          <span>{orderDetailsToShow?.address_line_1}</span>
                          <span>{orderDetailsToShow?.address_line_2}</span>
                          <span>
                            {orderDetailsToShow?.city} {", "}{" "}
                            {orderDetailsToShow?.country}
                          </span>
                          <span>{orderDetailsToShow?.postal_code}</span>
                        </div>

                        <div class="jkrYrOPNkSL7fkdfG1jW">
                          <button
                            onClick={() => setIsEdit(true)}
                            class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    )}

                    {isEdit && (
                      <div class="q4aopOnggp2NoeHw7lAZ">
                        <form onSubmit={handleSubmit} autocomplete="off">
                          <div class="Ol5sCKYGyQSPFdbFn_QV mNQlLGz2_naGtes9kKDM">
                            <div class="VnBpn0GY4tQEXz8hKHDQ">
                              <label
                                style={{
                                  fontFamily: "Overlock",
                                  fontWeight: "bold",
                                }}
                                for="name"
                              >
                                Full name
                              </label>
                              <input
                                id="name"
                                type="text"
                                name="name"
                                autoComplete="off"
                                maxLength="35"
                                value={formData.name}
                                onChange={handleInputChange}
                              />
                              {errors.name && (
                                <div style={{ color: "red" }} className="error">
                                  {errors.name}
                                </div>
                              )}
                            </div>

                            <div class="VnBpn0GY4tQEXz8hKHDQ">
                              <h4>Delivery address line 1</h4>
                              <div class="address-lookup">
                                <input
                                  name="address_line_1"
                                  type="text"
                                  className="input--full qas-typeahead"
                                  autoComplete="off"
                                  value={formData.address_line_1}
                                  onChange={handleInputChange}
                                />
                                {errors.address_line_1 && (
                                  <div
                                    style={{ color: "red" }}
                                    className="error"
                                  >
                                    {errors.address_line_1}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div class="VnBpn0GY4tQEXz8hKHDQ">
                              <h4>Delivery address line 2</h4>
                              <div class="address-lookup">
                                <input
                                  name="address_line_2"
                                  type="text"
                                  className="input--full qas-typeahead"
                                  autoComplete="off"
                                  value={formData.address_line_2}
                                  onChange={handleInputChange}
                                />
                                {errors.address_line_2 && (
                                  <div
                                    style={{ color: "red" }}
                                    className="error"
                                  >
                                    {errors.address_line_2}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="checkout-form-contact-info">
                              <div class="VnBpn0GY4tQEXz8hKHDQ">
                                <h4>City</h4>
                                <input
                                  name="city"
                                  type="text"
                                  className="input--full qas-typeahead input-integrity-address"
                                  autoComplete="off"
                                  value={formData.city}
                                  onChange={handleInputChange}
                                />
                                {errors.city && (
                                  <div
                                    style={{ color: "red" }}
                                    className="error"
                                  >
                                    {errors.city}
                                  </div>
                                )}
                              </div>

                              <div class="VnBpn0GY4tQEXz8hKHDQ">
                                <h4>Country</h4>
                                <input
                                  name="country"
                                  type="text"
                                  className="input--full qas-typeahead input-integrity-address"
                                  autoComplete="off"
                                  value={formData.country}
                                  onChange={handleInputChange}
                                />
                                {errors.country && (
                                  <div
                                    style={{ color: "red" }}
                                    className="error"
                                  >
                                    {errors.country}
                                  </div>
                                )}
                              </div>

                              <div class="VnBpn0GY4tQEXz8hKHDQ">
                                <h4>Postcode</h4>
                                <input
                                  name="postcode"
                                  type="text"
                                  className="input--full qas-typeahead input-integrity-address"
                                  autoComplete="off"
                                  value={formData.postcode}
                                  onChange={handleInputChange}
                                />
                                {errors.postcode && (
                                  <div
                                    style={{ color: "red" }}
                                    className="error"
                                  >
                                    {errors.postcode}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div class="MHy6bTDZkTWSg7sb6T_A">
                            <button
                              onClick={handleSaveRecipientClick}
                              class="VRZl5T_cEakrM5LuPOBb YLxdcrrIEpFGSqqBDTXs"
                              type="submit"
                            >
                              Save
                            </button>
                            <button
                              onClick={() => setIsEdit(false)}
                              class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs"
                            >
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {!isEdit && (
                <div class="MHy6bTDZkTWSg7sb6T_A">
                  <button
                    onClick={handleRecipientAddressNameChange}
                    class="VRZl5T_cEakrM5LuPOBb YLxdcrrIEpFGSqqBDTXs"
                  >
                    {isLoading ? (
                      <span>
                        <Loader />
                      </span>
                    ) : (
                      <span>Save</span>
                    )}
                  </button>
                  <button
                    onClick={() => closeModal()}
                    class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs"
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </dialog>
  );
};

export default ChangeRecipient;
