import React, { useEffect, useState } from "react";
import "./search.css";
import { Link, useParams } from "react-router-dom";
import { searchProducts } from "../../../api/api";

const imageUrl = process.env.REACT_APP_IMAGE_URL;
const Search = () => {
  const [searchData, setSearchData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    loadSearchResult();
  }, [slug]);

  const loadSearchResult = async () => {
    try {
      const response = await searchProducts({ title: slug });
      if (response?.data?.message === "Success") {
        setSearchData(response?.data.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  console.log("searchData", searchData);
  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <main class="a85u87S0FXgB7cibbD59">
        <div class="row">
          <div class="col-12">
            <div class="category-breadcrumb">
              <ul class="breadcrumb">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Search</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div class="YUryRFnU6UiPTD_YF6V7">
              <h2
                style={{ fontFamily: "Overlock", fontWeight: "bold" }}
                class="lJY7MXoKjidlBAsi3Xxg"
              >
                {slug}
              </h2>
              <div class="XLFPjQvLCuJisGnaJ2Wa">
                You searched for "{slug}", below are {searchData?.length}{" "}
                products which matched your search.
              </div>
            </div>
          </div>
        </div>
        <div class="eSp0Ptdp6adGzeQYcUAw">
          {searchData &&
            searchData.map((product, i) => {
              return (
                <div key={i} class="_yAEQ5RaR87EE0i5m86b">
                  <div class="text-center">
                    <Link
                      onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                      }
                      to={`/product/${product.id}`}
                    >
                      <img
                        class="img-fluid"
                        src={
                          imageUrl +
                          product?.product_variation[0]?.product_gallery[0]
                            ?.gallery_image
                        }
                        alt="Alcohol Free Elegance Sparkling"
                        width="285"
                        height="285"
                        loading="lazy"
                      />
                    </Link>
                  </div>
                  <div class="LrSBFs9Cjm3W7ppPILiL">
                    <h3 class="zWNqrQKgvUN2QB07O_dP">
                      <Link
                        onClick={() =>
                          window.scrollTo({ top: 0, behavior: "smooth" })
                        }
                        to={`/product/${product.id}`}
                        style={{ fontFamily: "Overlock" }}
                      >
                        {product.product_name}
                      </Link>
                    </h3>
                    <div
                      style={{ fontFamily: "Overlock" }}
                      class="bo8oJd52T3hzyJ76k8vN"
                    >
                      {product?.product_variation[0]?.variation_description}
                    </div>
                    <div>
                      {product?.product_variation[0]?.sale_price !== null ? (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p
                            style={{
                              marginRight: "10px",
                              textDecoration: "line-through",
                              fontFamily: "Overlock",
                            }}
                            class="_gZi40WOUBBOiZObY0NI colorGray"
                          >
                            £{product?.product_variation[0]?.variation_price}
                          </p>
                          <p
                            style={{
                              fontFamily: "Overlock",
                              fontWeight: "bold",
                            }}
                            class="_gZi40WOUBBOiZObY0NI"
                          >
                            £{product?.product_variation[0]?.sale_price}
                          </p>
                        </div>
                      ) : (
                        <p
                          style={{
                            fontFamily: "Overlock",
                            fontWeight: "bold",
                          }}
                          class="_gZi40WOUBBOiZObY0NI"
                        >
                          £{product?.product_variation[0]?.sale_price}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </main>
    </main>
  );
};

export default Search;
