import React from "react";
import { Link } from "react-router-dom";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const LandingFirstProductCompSideSingleProduct = ({ product }) => {
  return (
    <div class="product-container">
      <Link to={`/product/${product.id}`}>
        <img
          class="img-fluid"
          src={
            imageUrl +
            product?.product_variation[0]?.product_gallery[0]?.gallery_image
          }
          alt="Mauve Magic"
          width="255"
          height="255"
          loading="lazy"
        />
      </Link>
      {/* <div class="favourite-button-container">
        <span class="favourite-button">
          <span class="background">
            <i
              class="fa fa-heart-o heart"
              role="button"
              aria-label="Add to Favourites"
            ></i>
          </span>
        </span>
      </div> */}
      <div class="product-details">
        <Link
          class="name"
          style={{ fontFamily: "Overlock" }}
          to={`/product/${product.id}`}
        >
          {product?.product_name}
        </Link>

        {/* <Link class="price" to={`/product/${product.id}`}>
          £{product?.product_variation[0]?.variation_price}
        </Link> */}
        {product?.product_variation[0].sale_price !== null ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginRight: "10px",
            }}
          >
            <Link
              style={{ marginRight: "10px", fontFamily: "Overlock" }}
              class="price colorGray"
              to={`/product/${product[0]?.id}`}
            >
              £ {product?.product_variation[0]?.variation_price}
            </Link>
            <Link
              class="price"
              style={{ fontFamily: "Overlock", fontWeight: "bold" }}
              to={`/product/${product[0]?.id}`}
            >
              £ {product?.product_variation[0]?.sale_price}
            </Link>
          </div>
        ) : (
          <Link
            class="price"
            style={{ fontFamily: "Overlock", fontWeight: "bold" }}
            to={`/product/${product[0]?.id}`}
          >
            £ {product?.product_variation[0]?.variation_price}
          </Link>
        )}
      </div>
    </div>
  );
};

export default LandingFirstProductCompSideSingleProduct;
