import React, { useEffect, useState } from "react";
import LandingFirstProductCompSideSingleProduct from "./LandingFirstProductCompSideSingleProduct";
import { getProductsByCategory, random_products } from "../../../../../api/api";
import { Link } from "react-router-dom";
import CircleLoader from "../../../../Loader/CircleLoader";
import { useSelector } from "react-redux";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const LandingFirstProductComp = () => {
  const [products, setProducts] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const response = await getProductsByCategory(2);
      if (response?.data?.data) {
        const allProducts = response.data.data;
        const shuffledProducts = shuffleArray(allProducts);
        const randomProducts = shuffledProducts.slice(0, 5);
        setProducts(randomProducts);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };
  return (
    <>
      {products ? (
        <section
          class="five-product-bestseller"
          aria-label="Five Best Selling Products"
        >
          {products && (
            <div class="column-one">
              <div class="product-container pick-of-the-day">
                <Link to={`/product/${products[0]?.id}`}>
                  <img
                    class="img-fluid"
                    src={
                      imageUrl +
                      products[0]?.product_variation[0]?.product_gallery[0]
                        ?.gallery_image
                    }
                    alt="Mulberry Blush"
                    width="540"
                    height="632"
                    loading="lazy"
                  />
                </Link>
                <div class="product-details">
                  <div class="three">
                    <Link
                      class="name"
                      style={{ fontFamily: "Overlock" }}
                      to={`/product/${products[0]?.id}`}
                    >
                      {products[0]?.product_name}
                    </Link>
                    {/* <Link
                      class="description"
                      to={`/product/${products[0]?.id}`}
                    >
                      {products[0]?.product_variation[0]?.variation_description}
                    </Link> */}
                  </div>
                  {products[0]?.product_variation[0]?.sale_price !== null ? (
                    <div style={{ display: "flex" }}>
                      <Link
                        class="price colorGray"
                        style={{
                          marginRight: "10px",
                          fontFamily: "Overlock",
                        }}
                        to={`/product/${products[0]?.id}`}
                      >
                        £ {products[0]?.product_variation[0]?.variation_price}
                      </Link>
                      <Link
                        class="price"
                        style={{
                          fontFamily: "Overlock",
                          fontWeight: "bold",
                        }}
                        to={`/product/${products[0]?.id}`}
                      >
                        £ {products[0]?.product_variation[0]?.sale_price}
                      </Link>
                    </div>
                  ) : (
                    <Link
                      class="price"
                      style={{
                        fontFamily: "Overlock",
                        fontWeight: "bold",
                      }}
                      to={`/product/${products[0]?.id}`}
                    >
                      £ {products[0]?.product_variation[0]?.variation_price}
                    </Link>
                  )}

                  {/* <div class="favourite-button-container">
              <span class="favourite-button">
                <span class="background">
                  <i
                    class="fa fa-heart-o heart"
                    role="button"
                    aria-label="Add to Favourites"
                  ></i>
                </span>
              </span>
            </div> */}
                </div>
              </div>
            </div>
          )}
          <div class="column-two">
            {products &&
              products.map((p, i) => {
                if (i >= 1) {
                  return (
                    <LandingFirstProductCompSideSingleProduct product={p} />
                  );
                }
              })}

            {/* <LandingFirstProductCompSideSingleProduct />
        <LandingFirstProductCompSideSingleProduct />
        <LandingFirstProductCompSideSingleProduct /> */}
          </div>
        </section>
      ) : (
        <div>
          <CircleLoader />
        </div>
      )}
    </>
  );
};

export default LandingFirstProductComp;
