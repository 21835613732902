import React from "react";
import { Link } from "react-router-dom";

const ProductSingleItem = ({ product }) => {
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  return (
    <div class="item">
      <div class="favourite-button-container">
        <Link
          key={product.id}
          to={`/product/${product.id}`}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          style={{ pointerEvents: "all" }}
        >
          <img
            style={{ cursor: "grab" }}
            class="img-fluid"
            src={
              imageUrl +
              product?.product_variation[0]?.product_gallery[0]?.gallery_image
            }
            alt="Three Cheese Hamper"
            width="285"
            height="285"
            loading="lazy"
          />
        </Link>
        {/* <span class="favourite-button">
          <span class="background">
            <i
              class="fa fa-heart-o heart"
              role="button"
              aria-label="Add to Favourites"
            ></i>
          </span>
        </span> */}
      </div>
      <Link
        to={`/product/${product.id}`}
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        style={{ pointerEvents: "all" }}
      >
        <div class="content-holder">
          <h3 class="title">{product?.product_name}</h3>
          {/* <p class="price">£{product?.product_variation[0]?.variation_price}</p> */}
          {product?.product_variation[0]?.sale_price !== null ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <p class="_gZi40WOUBBOiZObY0NI colorGray price">
                £{product?.product_variation[0]?.variation_price}
              </p>
              <p
                class="_gZi40WOUBBOiZObY0NI price"
                style={{
                  marginLeft: "5px",
                  fontWeight: "bold",
                  fontFamily: "Overlock",
                }}
              >
                £{product?.product_variation[0]?.sale_price}
              </p>
            </div>
          ) : (
            <p class="_gZi40WOUBBOiZObY0NI price">
              £{product?.product_variation[0]?.variation_price}
            </p>
          )}
        </div>
      </Link>
    </div>
  );
};

export default ProductSingleItem;
