import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AllCartItems,
  RemoveMultipleItemsToBasket,
  sendForgetMail,
  signIn,
  storeMultipleItemsToBasket,
  verifyOtp,
} from "../../../api/api";
import Cookies from "js-cookies";
import { useDispatch } from "react-redux";
import { addToken, addUser } from "../../../features/User/userSlice";
import { v4 as uuidv4 } from "uuid";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(null);
  const [signInError, setSignInError] = useState("");

  const [sendOtp, isSendOtp] = useState(false);

  const handleSendMail = async (e) => {
    e.preventDefault();
    try {
      const response = await sendForgetMail({ email });
      setSignInError(response.data.message);
      if (response.data.message === "OTP has been sent") {
        isSendOtp(true);
        setOtp(null);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await verifyOtp({ email, otp });
      setSignInError(response.data.message);
      setTimeout(() => {
        // OTP Matched!
        navigate(`/forgot-password/${otp}`, { state: { email } });
      }, 500);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <main id="main-container" class="container top-margin">
      <div class="nav-blackout"></div>
      <div class="login">
        <div class="row">
          <div class="col-12">
            <ul class="breadcrumb">
              <li>
                <a href="/">Home</a>
              </li>
              <li>Forgot Password</li>
            </ul>
          </div>
        </div>
        <div class="heading">Forgot Password</div>
        <div class="content">
          <div class="form">
            <div class="form-group">
              <label
                style={{ fontFamily: "Overlock", fontWeight: "bold" }}
                for="email"
                class="bn-label"
              >
                Email Address
              </label>
              <input
                id="email"
                type="email"
                autocomplete="email"
                class="form-control"
                placeholder="Enter your email address..."
                required=""
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setSignInError("");
                }}
              />
            </div>

            {sendOtp && (
              <div class="form-group">
                <label
                  style={{ fontFamily: "Overlock", fontWeight: "bold" }}
                  for="otp"
                  class="bn-label"
                >
                  Enter OTP
                </label>
                <input
                  id="otp"
                  type="otp"
                  class="form-control"
                  placeholder="Enter your otp..."
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                    setSignInError("");
                  }}
                />
              </div>
            )}
            <div
              style={{ color: "red", fontSize: "15px", marginBottom: "18px" }}
            >
              {signInError}
            </div>
            <div class="buttons">
              {!sendOtp ? (
                <button
                  onClick={handleSendMail}
                  class="bn-button bn-button--primary"
                >
                  Send Mail
                </button>
              ) : (
                <button
                  onClick={handleVerifyOtp}
                  class="bn-button bn-button--primary"
                >
                  Verify OTP
                </button>
              )}
            </div>
          </div>
          <div class="benefits">
            <h1 style={{ fontFamily: "Overlock", fontWeight: "bold" }}>
              New to Integrity Flowers?
            </h1>
            <h2>Here are the benefits you get when you register:</h2>
            <p class="toggle active">
              Registration benefits<i class="fa fa-angle-down"></i>
            </p>
            <div>
              {/* <div class="reason">
                <img
                  src="https://media.bunches.co.uk/content_pages/posy-points-icon.png"
                  alt="Posy Points"
                />
                <span>
                  <h3>Posy Points™</h3>
                  Earn points on every order which you can claim for a discount
                  on future orders.
                </span>
              </div> */}
              <div class="reason">
                <img
                  src="https://media.bunches.co.uk/content_pages/address-icon.png"
                  alt="Address Book"
                />
                <span>
                  <h3>Address book</h3>
                  All of your addresses safely stored in one place for a faster
                  checkout experience.
                </span>
              </div>
              <div class="reason">
                <img
                  src="https://media.bunches.co.uk/content_pages/reminders-icon.png"
                  alt="Reminder Service"
                />
                <span>
                  <h3>Reminder Service</h3>
                  Let us know when your special occasions are and we will remind
                  you by email.
                </span>
              </div>
            </div>
            <div class="text-center">
              <Link
                class="bn-button bn-button--primary"
                role="button"
                to="/register"
              >
                Register
              </Link>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ForgotPassword;
