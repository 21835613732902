import React, { useEffect, useRef } from "react";
import { useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
// import "./ChooseDate.css";
import {
  change_delivery_date,
  change_delivery_date_by_cart_id,
  change_delivery_date_by_order_id,
  creatCartItem,
} from "../../api/api";
import { useDispatch } from "react-redux";
import {
  addBasket,
  addSingleItemToBasket,
  changeDeliveryDate,
} from "../../features/basket/basket";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import Loader from "../Loader/Loader";

const format = "YYYY/MM/DD";

const ChooseDate = ({
  unAvailableDates,
  productVariation,
  product,
  setIsChangeDelivery,
  orderId,
  sendDeliveryDateToParent,
}) => {
  const user = useSelector((state) => state.user);
  const userBasket = useSelector((state) => state?.basket?.basket);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const datePickerRef = useRef();
  const [datesToRender, setDatesToRender] = useState();
  const [date, setDate] = useState();

  const [token, setToken] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = Cookies.get("token");
    setToken(token);
  }, []);

  useEffect(() => {
    if (!datesToRender) return;
    const { day, date, month } = datesToRender[0];
    const dateString = `${day} ${date} ${month} 2024`;
    const newDate = new Date(dateString);
    setDate(newDate);
  }, [datesToRender]);

  const formatDate = (d) => {
    const date = d instanceof Date ? d : new Date(d);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const dayOfWeek = days[date.getDay()];

    // Add ordinal suffix to the day of the month
    const suffix =
      dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31
        ? "st"
        : dayOfMonth === 2 || dayOfMonth === 22
        ? "nd"
        : dayOfMonth === 3 || dayOfMonth === 23
        ? "rd"
        : "th";

    return `${dayOfWeek} ${dayOfMonth}${suffix} ${month} ${year}`;
  };

  useEffect(() => {
    const unAvailableDateArray = unAvailableDates.map((d) => d?.date);
    const today = new Date();

    const newDates = [];

    let currentDate = new Date(today);
    currentDate.setDate(currentDate.getDate() + 1);
    setDate(currentDate);

    for (let i = 0; i < 4; ) {
      const dateString = currentDate.toISOString().slice(0, 10);
      if (!unAvailableDateArray.includes(dateString)) {
        const day = currentDate.toLocaleDateString("en-US", {
          weekday: "short",
        });
        const date = currentDate.getDate();
        const month = currentDate.toLocaleDateString("en-US", {
          month: "short",
        });
        newDates.push({ day, date, month });
        i++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    setDatesToRender(newDates);
  }, [unAvailableDates]);

  const handleDateClick = (d) => {
    setDate(d);
  };

  const handlePlaceOrderClick = async () => {
    setIsLoading(true);
    var dateToSend;

    if (date) {
      if (date instanceof Date) {
        dateToSend = new Date(date);
      } else {
        dateToSend = new Date(date.format());
      }
    }

    if (orderId) {
      try {
        const response = await change_delivery_date_by_order_id({
          order_id: orderId,
          delivery_date: dateToSend
            .toLocaleDateString("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .split("/")
            .reverse()
            .join("-"),
        });
        if (response) {
          setIsChangeDelivery(false);
          sendDeliveryDateToParent(
            dateToSend
              .toLocaleDateString("en-GB", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .split("/")
              .reverse()
              .join("-")
          );
        }
      } catch (error) {
        console.log("error", error);
      }

      // return if its order page
      return;
    }
    try {
      const response = await change_delivery_date_by_cart_id({
        cart_id: userBasket[0]?.id,
        delivery_date: dateToSend
          .toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .split("/")
          .reverse()
          .join("-"),
      });
      if (response) {
        setIsChangeDelivery(false);
        dispatch(
          changeDeliveryDate({
            id: userBasket[0]?.id,
            date: dateToSend
              .toLocaleDateString("en-GB", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
              .split("/")
              .reverse()
              .join("-"),
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div class="D2EF3NsqOGV2NbxgF2zV">
        <div class="D2EF3NsqOGV2NbxgF2zV">
          <div class="FKb_nzN6LdxS4vZJ0gB_">
            <div class="mBWeb1lUN5JEXQRhEko6">
              {datesToRender &&
                datesToRender.map((d, index) => {
                  const monthsMap = {
                    Jan: 0,
                    Feb: 1,
                    Mar: 2,
                    Apr: 3,
                    May: 4,
                    Jun: 5,
                    Jul: 6,
                    Aug: 7,
                    Sep: 8,
                    Oct: 9,
                    Nov: 10,
                    Dec: 11,
                  };
                  const dDate = new Date(
                    new Date(date).getFullYear(),
                    monthsMap[d.month],
                    d.date
                  );

                  return (
                    <div
                      onClick={() => handleDateClick(dDate)}
                      class={`gMkDRDyhBE30Q_jacrb9 ${
                        new Date(date).toDateString() === dDate.toDateString()
                          ? "DonwU97LybSqNE1zMDzI"
                          : ""
                      }`}
                      aria-selected={
                        new Date(date).toDateString() === dDate.toDateString()
                      }
                      role="button"
                    >
                      <span class="nXUb5pJNX5UjBkKZJKZt">{d.day}</span>
                      <span>{d.date}th</span>
                      <span>{d.month}</span>
                    </div>
                  );
                })}

              <div
                class="gMkDRDyhBE30Q_jacrb9 gvWeahtpr0pWvuIWSop_"
                role="button"
              >
                <svg
                  onClick={() => datePickerRef.current.openCalendar()}
                  style={{ position: "relative", zIndex: "1" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  fill="currentColor"
                  class="bi bi-calendar2-week"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z" />
                  <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z" />
                </svg>
                <DatePicker
                  ref={datePickerRef}
                  value={date}
                  highlightToday={false}
                  calendarPosition="bottom-center"
                  onChange={setDate}
                  sort
                  format={format}
                  minDate={new Date()}
                  mapDays={({ date }) => {
                    if (!(date instanceof Date)) {
                      date = new Date(date);
                    }
                    const currentDateDay = date.getDate();
                    const currentDateMonth = date.getMonth();
                    const currentDateYear = date.getFullYear();

                    const isUnavailable = unAvailableDates.some(
                      (unavailableDate) => {
                        const unavailableDateObject = new Date(
                          unavailableDate.date
                        );

                        const unavailableDateDay =
                          unavailableDateObject.getDate();
                        const unavailableDateMonth =
                          unavailableDateObject.getMonth();
                        const unavailableDateYear =
                          unavailableDateObject.getFullYear();

                        return (
                          currentDateDay === unavailableDateDay &&
                          currentDateMonth === unavailableDateMonth &&
                          currentDateYear === unavailableDateYear
                        );
                      }
                    );

                    if (isUnavailable) {
                      return {
                        disabled: true,
                        style: { color: "#ccc" },
                        onClick: () => alert("This date is not available"),
                      };
                    }
                  }}
                />
                <p onClick={() => datePickerRef.current.openCalendar()}>
                  More Dates
                </p>
              </div>
            </div>
            {/* <div class="oQASNIq07uEnhnfHZZRs">
          <div class="product-checkbox selected">
            <span class="tick-box">
              <span class="tick fa fa-check"></span>
            </span>
            Royal Mail Tracked 24 - £3.49
          </div>
          <div class="product-checkbox">
            <span class="tick-box"></span>Guaranteed Courier Delivery - £5.99
          </div>
        </div> */}
            <div class="BMAEqbbvuK5t8o2hZXgI">
              <p>
                Your selected delivery date is:{" "}
                <span
                  style={{ fontFamily: "Overlock", fontWeight: "bold" }}
                  class="bold"
                >
                  {date && formatDate(date)}
                </span>
              </p>
              <p>
                {/* <span>
              by Royal Mail Tracked. Whilst over 90% of orders arrive on your
              chosen date, there is a chance it will arrive on the next working
              day. You will receive a tracking email, so you know when your gift
              has been delivered.
            </span> */}
              </p>
            </div>
          </div>
        </div>
        {/* <button
        
        type="button"
        class="bn-button bn-button--primary place-order"
      >
        Place Order
      </button> */}
      </div>
      <div class="LEhEQdesB7UmdWjwaKSv">
        <button
          onClick={handlePlaceOrderClick}
          class="VRZl5T_cEakrM5LuPOBb YLxdcrrIEpFGSqqBDTXs"
        >
          {isLoading ? (
            <span>
              <Loader />
            </span>
          ) : (
            <span>Save and continue</span>
          )}
        </button>
        <button
          onClick={() => setIsChangeDelivery(false)}
          class="e7QvJMPhPw6Pbciz9UHc YLxdcrrIEpFGSqqBDTXs"
        >
          Cancel
        </button>
      </div>
    </>
  );
};

export default ChooseDate;
