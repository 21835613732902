import React, { useEffect, useState } from "react";
import { useUserContext } from "./UserContext";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  checkCoupon,
  createOrder,
  shipping_charges,
  vat_tax,
} from "../../../api/api";
import PaymentModal from "../../PaymentModal/PaymentModal";
import ChangeDateForCartItem from "../../ChangeDateForCartItem/ChangeDateForCartItem";
import {
  addCoupon,
  addCouponResponse,
} from "../../../features/checkout/CouponSlice";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const DeliveryFinalSection = ({
  basketTotalPayment,
  setCurrentPage,
  userToken,
}) => {
  const dispatch = useDispatch();
  const couponState = useSelector((state) => state.coupon);
  const allProducts = useSelector((state) => state?.products?.products);
  const basketState = useSelector((state) => state?.basket?.basket);
  const basketItems = useSelector((state) => state?.basket?.basket);
  const { userData, updateUserData } = useUserContext();

  const [isPayment, setIsPayment] = useState(false);

  const [vat, setVat] = useState(10);

  const [isChangeDelivery, setIsChangeDelivery] = useState(false);

  const [cartItemProduct, setCartItemProduct] = useState(null);
  const [cartItemProductVariation, setCartItemProductVariation] =
    useState(null);

  const [orderId, setOrderId] = useState("");

  const [allCartItems, setAllCartItems] = useState(null);

  const [couponResponse, setCouponResponse] = useState(null);
  const [coupon, setCoupon] = useState("");
  const [couponError, setCouponError] = useState("");

  const [deliveryCharge, setDeliveryCharge] = useState("");

  useEffect(() => {
    setAllCartItems(basketItems);
  }, [basketItems]);

  useEffect(() => {
    loadDeliveryCharge();
    const loadVatTax = async () => {
      try {
        const response = await vat_tax();
        setVat(response?.data?.data?.tax_amount);
      } catch (error) {
        console.log("error", error);
      }
    };
    loadVatTax();
  }, []);

  const loadDeliveryCharge = async () => {
    try {
      const response = await shipping_charges();
      setDeliveryCharge(response?.data?.data?.shipping_charges);
    } catch (error) {
      console.log("error", error);
    }
  };

  const formatDate = (d) => {
    const date = d instanceof Date ? d : new Date(d);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const dayOfWeek = days[date.getDay()];

    // Add ordinal suffix to the day of the month
    const suffix =
      dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31
        ? "st"
        : dayOfMonth === 2 || dayOfMonth === 22
        ? "nd"
        : dayOfMonth === 3 || dayOfMonth === 23
        ? "rd"
        : "th";

    return `${dayOfWeek} ${dayOfMonth}${suffix} ${month} ${year}`;
  };
  const handlePaymentClick = async () => {
    setIsPayment(true);

    const formData = new FormData();
    formData.append("cart_id[0]", basketState[0]?.id);
    formData.append("city[0]", userData?.city);
    formData.append("country[0]", "United Kingdom");
    formData.append("address_line_1[0]", userData?.address_line_1);
    formData.append("address_line_2[0]", userData?.address_line_2);
    formData.append("postal_code[0]", userData?.postcode);
    formData.append("card_message[0]", userData?.card_message);
    formData.append("recipient_name[0]", userData?.name);
    formData.append("recipient_phone[0]", userData?.phone);
    formData.append("coupon_code[0]", couponState?.coupon);

    try {
      const response = await createOrder(formData);
      setOrderId(response?.data?.data?.id);
      // dispatch(addCoupon(""));
      // dispatch(addCouponResponse(""));
    } catch (error) {
      console.log("error", error);
    }
  };

  const paymentSuccessOfOrder = async () => {};

  const handleDeliveryDateChangeClick = () => {
    setIsChangeDelivery(true);

    if (allProducts) {
      const cartItemProduct = allProducts.filter((prod) => {
        return (
          prod.id === basketState[0]?.product_variation?.product_detail?.id
        );
      });
      setCartItemProduct(cartItemProduct[0]);

      const productVariation = cartItemProduct[0]?.product_variation.filter(
        (variation) => {
          return variation?.id === basketState[0]?.product_variation?.id;
        }
      );
      setCartItemProductVariation(productVariation);
    }
  };

  const handleCouponChange = (e) => {
    setCouponError("");
    setCouponResponse(null);
    setCoupon(e.target.value);
  };
  const handleCouponApplyClick = async () => {
    if (coupon == "") {
      setCouponError("Please Enter Coupon Code");
      dispatch(addCouponResponse(""));
      return;
    }
    try {
      let cartTotalPrise;
      if (Number(vat) !== 0) {
        cartTotalPrise =
          allCartItems[0]?.item_sale_price !== null
            ? allCartItems[0]?.item_sale_price +
              allCartItems[0]?.item_sale_price / vat
            : allCartItems[0]?.item_price + allCartItems[0]?.item_price / vat;
      } else {
        cartTotalPrise =
          allCartItems[0]?.item_sale_price !== null
            ? allCartItems[0]?.item_sale_price
            : allCartItems[0]?.item_price;
      }
      console.log("cartTotalPrise", cartTotalPrise);
      console.log("vat", vat);
      const response = await checkCoupon({
        cart_total: cartTotalPrise,
        coupon_code: coupon,
      });
      if (response?.data?.message === "Success") {
        setCoupon("");
        dispatch(addCoupon(coupon));
        dispatch(addCouponResponse(response?.data));
        setCouponResponse(response?.data);
      }
    } catch (error) {
      setCouponError(error?.response?.data?.message);
      dispatch(addCoupon(""));
      dispatch(addCouponResponse(""));
      setCouponResponse(null);
      console.log("error", error);
    }
  };
  // basketState[0]?.item_sale_price
  const amountToConsider =
    basketState[0]?.item_sale_price !== null
      ? basketState[0]?.item_sale_price
      : basketState[0]?.item_price;

  const finalAmount =
    couponState?.couponVerification !== ""
      ? (
          Number(amountToConsider) -
          Number(couponState?.couponVerification?.discount) +
          Number(deliveryCharge)
        ).toFixed(2)
      : (Number(amountToConsider) + Number(deliveryCharge)).toFixed(2);
  return (
    <>
      {cartItemProduct && cartItemProductVariation && (
        <ChangeDateForCartItem
          isChangeDelivery={isChangeDelivery}
          setIsChangeDelivery={setIsChangeDelivery}
          cartItemProduct={cartItemProduct}
          cartItemProductVariation={cartItemProductVariation}
        />
      )}
      {basketState && (
        <PaymentModal
          paymentSuccessOfOrder={paymentSuccessOfOrder}
          isPayment={isPayment}
          setIsPayment={setIsPayment}
          amountForPayment={finalAmount}
          orderId={orderId}
        />
      )}
      <section id="delivery" class="F8D7tEzAQUW7EsIPEric">
        <div class="Y7LFkBgvgiofX2IQKUGQ">
          <div class="qnwlewn2cssXojz8JJDN">
            <div class="uSALEIQsrdvIGHiKC4tO">
              <span>
                <h1>
                  {
                    basketState[0]?.product_variation?.product_detail
                      ?.product_name
                  }
                </h1>
                {basketState[0]?.item_sale_price !== null ? (
                  <div style={{ display: "flex" }}>
                    Price:
                    <p
                      className="colorGray"
                      style={{ marginRight: "10px", marginLeft: "3px" }}
                    >
                      £{Number(basketState[0]?.item_price).toFixed(2)}
                    </p>
                    <p>£{Number(basketState[0]?.item_sale_price).toFixed(2)}</p>
                  </div>
                ) : (
                  <div style={{ display: "flex" }}>
                    Price:
                    <p>£{Number(basketState[0]?.item_price).toFixed(2)}</p>
                  </div>
                )}
              </span>
              <div>
                <div class="UntWHkcwCDfpW2otHL22">
                  <img
                    src={
                      basketState &&
                      imageUrl +
                        basketState[0]?.product_variation?.product_gallery[0]
                          ?.gallery_image
                    }
                    width="56"
                    height="56"
                    alt="Photograph of Rhubarb and Custard"
                  />
                </div>
              </div>
            </div>
            <div class="cSbChp747LopTq7nHMDa">
              <Link
                onClick={() => {
                  if (userToken === undefined) {
                    setCurrentPage(3);
                  } else {
                    setCurrentPage(2);
                  }
                }}
                aria-label="Edit Recipient"
              >
                <img
                  src="https://media.bunches.co.uk/icons/pencil.svg "
                  alt="Edit Recipient"
                  class="o7xquVNwxlbKT9GqzWj2"
                  aria-hidden="true"
                />
              </Link>
              <span>
                <h2 style={{ fontFamily: "Overlock", fontWeight: "bold" }}>
                  Recipient
                </h2>
                <p>{userData?.name}</p>
                <p>
                  {userData?.address_line_1} {userData?.address_line_2}{" "}
                  {userData?.city} {userData?.country} {userData?.postcode}
                </p>
                {/* <span class="Lh3uDByRTNVMP2S75ihs E02GN3vPiDwi03VcE_Cc">
                The address for your order is invalid.
              </span> */}
              </span>
            </div>
            <div class="cSbChp747LopTq7nHMDa">
              <div
                onClick={handleDeliveryDateChangeClick}
                aria-label="Edit Delivery"
              >
                <img
                  src="https://media.bunches.co.uk/icons/pencil.svg"
                  alt="Edit Delivery"
                  class="o7xquVNwxlbKT9GqzWj2"
                />
              </div>
              <span>
                <h2 style={{ fontFamily: "Overlock", fontWeight: "bold" }}>
                  Delivery Date
                </h2>
                <p>
                  {formatDate(basketState[0]?.delivery_date)}
                  {/* <span>10th April</span> by Royal Mail Tracked 24 */}
                </p>
              </span>
            </div>
            <div class="cSbChp747LopTq7nHMDa">
              <Link
                aria-label="Edit Delivery"
                onClick={() => setCurrentPage(1)}
              >
                <img
                  src="https://media.bunches.co.uk/icons/pencil.svg"
                  alt="Edit Delivery"
                  class="o7xquVNwxlbKT9GqzWj2"
                />
              </Link>
              <span>
                <h2 style={{ fontFamily: "Overlock", fontWeight: "bold" }}>
                  Card Message :
                </h2>
                <p>{userData?.card_message}</p>
              </span>
            </div>

            {/* <div class="cSbChp747LopTq7nHMDa">
              <Link
                aria-label="Edit Delivery"
                onClick={() => setCurrentPage(1)}
              >
                <div style={{ width: "30px" }}></div>
              </Link>
              <span>
                <h2>VAT :</h2>
                <p>
                  {vat} % - £{basketState[0]?.item_price}
                </p>
              </span>
            </div> */}

            <div class="cSbChp747LopTq7nHMDa">
              <Link
                aria-label="Edit Delivery"
                onClick={() => setCurrentPage(1)}
              >
                <div style={{ width: "30px" }}></div>
              </Link>
              <span>
                <h2 style={{ fontFamily: "Overlock", fontWeight: "bold" }}>
                  Delivery Charge :
                </h2>
                <p style={{ fontFamily: "Overlock", fontWeight: "bold" }}>
                  £{deliveryCharge}
                </p>
              </span>
            </div>

            {couponState?.couponVerification !== "" ? (
              <div class="cSbChp747LopTq7nHMDa">
                <Link
                  aria-label="Edit Delivery"
                  onClick={() => {
                    dispatch(addCoupon(""));
                    dispatch(addCouponResponse(""));
                  }}
                >
                  <img
                    src="https://media.bunches.co.uk/icons/pencil.svg"
                    alt="Edit Delivery"
                    class="o7xquVNwxlbKT9GqzWj2"
                  />
                </Link>
                <span>
                  <h2>Promo/voucher code :</h2>
                  <p>
                    {couponState?.coupon ? couponState?.coupon : "NA"} - £
                    {(
                      (basketState[0]?.item_sale_price !== null
                        ? basketState[0]?.item_sale_price
                        : basketState[0]?.item_price ?? 0) -
                      couponState?.couponVerification?.discount
                    ).toFixed(2)}
                  </p>
                </span>
              </div>
            ) : (
              <section class="h2CJwqXTbGiqnOOs4v3w">
                <div
                  style={{ width: "95%", margin: "auto" }}
                  class="VnBpn0GY4tQEXz8hKHDQ LtGgg0G5_kft3k6Rogcm"
                >
                  <input
                    id="discountCode"
                    type="text"
                    placeholder="Enter your Promo/voucher code"
                    value={coupon}
                    onChange={handleCouponChange}
                  />
                  <button
                    onClick={handleCouponApplyClick}
                    class="VRZl5T_cEakrM5LuPOBb"
                    type="button"
                  >
                    Apply
                  </button>
                </div>
                {couponError !== "" && (
                  <div style={{ color: "red" }}>{couponError}</div>
                )}
                {couponState?.couponVerification !== "" && (
                  <div style={{ color: "green" }}>
                    Coupon code '{couponState.coupon}' applied. Discount: £{" "}
                    {Number(couponResponse?.discount).toFixed(2)}. Enjoy your
                    savings!
                  </div>
                )}
              </section>
            )}

            <div class="k3M6bwt4JpDlEm2O6e4_">
              {/* <div class="x8JPC3jUXumn43sBApaM">
              <i class="fa fa-shopping-basket" aria-hidden="true"></i>
              <span>
                <p>Rhubarb and Custard: £22.00</p>
              </span>
            </div> */}
              {/* <div class="cSbChp747LopTq7nHMDa Jb89uGTJVYr_wBUTjs5g">
              <span class="Lh3uDByRTNVMP2S75ihs E02GN3vPiDwi03VcE_Cc">
                Your order is missing a card. <span>Click here</span> to select
                a free or premium card.
              </span>
            </div> */}
              {/* <div class="x8JPC3jUXumn43sBApaM">
              <Link aria-label="Delivery" to="/checkout/1">
                <img
                  src="https://media.bunches.co.uk/icons/pencil.svg"
                  alt="Delivery"
                  class="o7xquVNwxlbKT9GqzWj2"
                  aria-hidden="true"
                />
              </Link>
              <span>
                <p>Delivery: £3.49</p>
              </span>
            </div> */}
              <h2 className="LqXSDX4TND9QahjDfEHr">
                Total: £ {finalAmount}
                {/* {couponState?.couponVerification !== ""
                  ? (
                      basketState[0]?.item_price -
                      couponState?.couponVerification?.discount +
                      Number(deliveryCharge)
                    ).toFixed(2)
                  : basketState[0]?.item_price + Number(deliveryCharge)} */}
              </h2>
              {/* {coupon?.coupon !== null && (
                <p style={{ textAlign: "center" }}>
                  Promo/voucher code{" "}
                  <span style={{ fontWeight: "bold" }}>{coupon?.coupon}</span>{" "}
                  Applied
                </p>
              )} */}
            </div>
            <div class="nhLMrWYJKH6Alqtdyusw"></div>
          </div>
          <div class="mgls3JTlSXUXcIBnkhd0">
            <button onClick={handlePaymentClick} class="VRZl5T_cEakrM5LuPOBb">
              Proceed to payment
            </button>
            {/* <Link class="e7QvJMPhPw6Pbciz9UHc" to="/">
            Add another order
          </Link> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default DeliveryFinalSection;
