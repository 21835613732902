import React, { useEffect, useRef } from "react";
import { useState } from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
import "./ChooseDelivery.css";
import { creatCartItem } from "../../../../api/api";
import { useDispatch } from "react-redux";
import {
  addBasket,
  addSingleItemToBasket,
} from "../../../../features/basket/basket";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import Loader from "../../../Loader/Loader";
import CustomDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../CostomDatePicker/CustomDatePicker.css";

const format = "YYYY/MM/DD";

const ChooseDelivery = ({
  unAvailableDates,
  productVariation,
  product,
  deliveryCharge,
}) => {
  const user = useSelector((state) => state.user);
  const userBasket = useSelector((state) => state?.basket?.basket);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const datePickerRef = useRef();
  const [datesToRender, setDatesToRender] = useState();
  const [date, setDate] = useState();

  const [token, setToken] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [notAvailableDates, setNotAvailableDates] = useState([]);

  useEffect(() => {
    setNotAvailableDates(unAvailableDates);
  }, [unAvailableDates]);

  useEffect(() => {
    if (product && product.globalUnavailableDates.length > 0) {
      setNotAvailableDates((prevDates) =>
        prevDates.concat(product.globalUnavailableDates)
      );
    }
    if (product && product.delayedDates.length > 0) {
      const transformedDates = product.delayedDates.map((date) => ({ date }));
      setNotAvailableDates((prevDates) => prevDates.concat(transformedDates));
    }
  }, [product]);
  useEffect(() => {
    const token = Cookies.get("token");
    setToken(token);
  }, []);

  useEffect(() => {
    if (!datesToRender) return;
    const { day, date, month } = datesToRender[0];
    const dateString = `${day} ${date} ${month} 2024`;
    const newDate = new Date(dateString);
    setDate(newDate);
  }, [datesToRender]);

  const formatDate = (d) => {
    const date = d instanceof Date ? d : new Date(d);
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const dayOfWeek = days[date.getDay()];

    // Add ordinal suffix to the day of the month
    const suffix =
      dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31
        ? "st"
        : dayOfMonth === 2 || dayOfMonth === 22
        ? "nd"
        : dayOfMonth === 3 || dayOfMonth === 23
        ? "rd"
        : "th";

    return `${dayOfWeek} ${dayOfMonth}${suffix} ${month} ${year}`;
  };

  useEffect(() => {
    if (!notAvailableDates) return;
    const unAvailableDateArray = notAvailableDates.map((d) => d?.date);
    const today = new Date();

    const newDates = [];

    let currentDate = new Date(today);
    currentDate.setDate(currentDate.getDate() + 1);
    setDate(currentDate);

    for (let i = 0; i < 4; ) {
      const dateString = currentDate.toISOString().slice(0, 10);
      if (!unAvailableDateArray.includes(dateString)) {
        const day = currentDate.toLocaleDateString("en-US", {
          weekday: "short",
        });
        const date = currentDate.getDate();
        const month = currentDate.toLocaleDateString("en-US", {
          month: "short",
        });
        newDates.push({ day, date, month });
        i++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    setDatesToRender(newDates);
  }, [notAvailableDates]);

  const handleDateClick = (d) => {
    setDate(d);
  };
  console.log("productVariation", productVariation);
  const handlePlaceOrderClick = async () => {
    setIsLoading(true);
    if (userBasket.length === 0) {
      var dateToSend;

      if (date) {
        if (date instanceof Date) {
          dateToSend = new Date(date);
        } else {
          dateToSend = new Date(date.format());
        }
      }

      const data = [
        {
          uid: uuidv4(),
          variation_id: productVariation?.id,
          item_price: productVariation?.variation_price,
          item_qty: 1,
          sale_price: productVariation?.sale_price,
          item_sale_price: productVariation?.sale_price,
          delivery_date: dateToSend
            .toLocaleDateString("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .split("/")
            .reverse()
            .join("-"),
          product_variation: {
            id: productVariation?.id,
            sale_price: productVariation?.sale_price,
            item_sale_price: productVariation?.sale_price,
            variation_short_label: productVariation?.variation_short_label,
            variation_label: productVariation?.variation_label,
            variation_price: productVariation?.variation_price,
            variation_description: productVariation.variation_description,
            product_id: productVariation?.product_id,
            product_detail: product,
          },
        },
      ];

      if (!token) {
        const localCart = localStorage.getItem("user-cart");

        if (!localCart) {
          localStorage.setItem("user-cart", JSON.stringify(data));
          dispatch(addBasket(data));
          navigate("/checkout/1");
        } else {
          const existingCart = JSON.parse(localCart);
          const updatedCart = [...existingCart, data[0]];

          localStorage.setItem("user-cart", JSON.stringify(updatedCart));
          dispatch(addBasket(updatedCart));
          navigate("/checkout/1");
        }
        return;
      }

      if (!productVariation) return;

      try {
        const localCart = localStorage.getItem("user-cart");
        let existingCart = JSON.parse(localCart);

        if (!Array.isArray(existingCart)) {
          existingCart = [];
        }

        const updatedCart = [...existingCart, data[0]];
        localStorage.setItem("user-cart", JSON.stringify(updatedCart));

        const formData = new FormData();

        formData.append("uid", uuidv4());
        formData.append("variation_id", productVariation?.id);
        formData.append("item_price", productVariation?.variation_price);
        formData.append("item_qty", "1");
        formData.append(
          "delivery_date",
          dateToSend
            .toLocaleDateString("en-GB", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .split("/")
            .reverse()
            .join("-")
        );

        const response = await creatCartItem(formData);
        if (response) {
          dispatch(addSingleItemToBasket());
          navigate("/checkout/1");
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      alert(
        "Please place your order or remove item from the basket before adding new ones."
      );
    }
  };
  console.log("unavailableDateObject", notAvailableDates);
  return (
    <>
      <div class="D2EF3NsqOGV2NbxgF2zV">
        <div style={{ padding: "0px" }} class="FKb_nzN6LdxS4vZJ0gB_">
          {/* <div class="mBWeb1lUN5JEXQRhEko6">
            {datesToRender &&
              datesToRender.map((d, index) => {
                const monthsMap = {
                  Jan: 0,
                  Feb: 1,
                  Mar: 2,
                  Apr: 3,
                  May: 4,
                  Jun: 5,
                  Jul: 6,
                  Aug: 7,
                  Sep: 8,
                  Oct: 9,
                  Nov: 10,
                  Dec: 11,
                };
                const dDate = new Date(
                  new Date(date).getFullYear(),
                  monthsMap[d.month],
                  d.date
                );

                return (
                  <div
                    onClick={() => handleDateClick(dDate)}
                    class={`gMkDRDyhBE30Q_jacrb9 ${
                      new Date(date).toDateString() === dDate.toDateString()
                        ? "DonwU97LybSqNE1zMDzI"
                        : ""
                    }`}
                    aria-selected={
                      new Date(date).toDateString() === dDate.toDateString()
                    }
                    role="button"
                  >
                    <span
                      style={{
                        fontFamily: "Overlock",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                      class="nXUb5pJNX5UjBkKZJKZt"
                    >
                      {d.day}
                    </span>
                    <span>{d.date}th</span>
                    <span>{d.month}</span>
                  </div>
                );
              })}

            <div
              class="gMkDRDyhBE30Q_jacrb9 gvWeahtpr0pWvuIWSop_"
              role="button"
            >
              <svg
                onClick={() => datePickerRef.current.openCalendar()}
                style={{ position: "relative", zIndex: "1" }}
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="currentColor"
                class="bi bi-calendar2-week"
                viewBox="0 0 16 16"
              >
                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z" />
                <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5zM11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z" />
              </svg>

              <DatePicker
                ref={datePickerRef}
                value={date}
                highlightToday={false}
                calendarPosition="bottom-center"
                onChange={setDate}
                sort
                format={format}
                minDate={new Date()}
                mapDays={({ date }) => {
                  if (!(date instanceof Date)) {
                    date = new Date(date);
                  }
                  const currentDateDay = date.getDate();
                  const currentDateMonth = date.getMonth();
                  const currentDateYear = date.getFullYear();

                  const isUnavailable = notAvailableDates.some(
                    (unavailableDate) => {
                      const unavailableDateObject = new Date(
                        unavailableDate.date
                      );

                      const unavailableDateDay =
                        unavailableDateObject.getDate();
                      const unavailableDateMonth =
                        unavailableDateObject.getMonth();
                      const unavailableDateYear =
                        unavailableDateObject.getFullYear();

                      return (
                        currentDateDay === unavailableDateDay &&
                        currentDateMonth === unavailableDateMonth &&
                        currentDateYear === unavailableDateYear
                      );
                    }
                  );

                  if (isUnavailable) {
                    return {
                      disabled: true,
                      style: { color: "#ccc" },
                      onClick: () => alert("This date is not available"),
                    };
                  }
                }}
              />
              <p onClick={() => datePickerRef.current.openCalendar()}>
                More Dates
              </p>
            </div>
          </div> */}
          <div className="custom-date-picker">
            <CustomDatePicker
              inline
              selected={date}
              highlightToday={false}
              calendarPosition="bottom-center"
              onChange={setDate}
              sort
              format={"MM/dd/yyyy"}
              minDate={new Date()} // Disable all past dates
              excludeDates={notAvailableDates.map(
                (dateObj) => new Date(dateObj.date)
              )} // Disable unavailable dates
              dayClassName={(date) => {
                const today = new Date();
                today.setHours(0, 0, 0, 0); // Set the time to 00:00:00 to avoid time comparison issues

                const isPastDate = date < today; // Check if the date is in the past
                const isUnavailable = notAvailableDates.some(
                  (unavailableDate) => {
                    const unavailableDateObj = new Date(unavailableDate.date);
                    return (
                      unavailableDateObj.toDateString() === date.toDateString()
                    );
                  }
                );

                // Apply a CSS class if the date is either past or unavailable
                return isPastDate || isUnavailable ? "grayed-out-date" : "";
              }}
              onClickOutside={() => {}}
            />
          </div>
          {/* <div class="oQASNIq07uEnhnfHZZRs">
          <div class="product-checkbox selected">
            <span class="tick-box">
              <span class="tick fa fa-check"></span>
            </span>
            Royal Mail Tracked 24 - £3.49
          </div>
          <div class="product-checkbox">
            <span class="tick-box"></span>Guaranteed Courier Delivery - £5.99
          </div>
        </div> */}
          <div class="BMAEqbbvuK5t8o2hZXgI">
            <p>
              Your selected delivery date is:{" "}
              <span
                style={{ fontFamily: "Overlock", fontWeight: "bold" }}
                class="bold"
              >
                {date && formatDate(date)}
              </span>
            </p>
            <p>
              {/* <span>
              by Royal Mail Tracked. Whilst over 90% of orders arrive on your
              chosen date, there is a chance it will arrive on the next working
              day. You will receive a tracking email, so you know when your gift
              has been delivered.
            </span> */}
            </p>
          </div>
          <div className="BMAEqbbvuK5t8o2hZXgI">
            Delivery Charge - <span class="bold">£{deliveryCharge}</span>
          </div>
        </div>
      </div>
      <button
        onClick={handlePlaceOrderClick}
        type="button"
        class="bn-button bn-button--primary place-order"
      >
        {isLoading ? (
          <span>
            <Loader />
          </span>
        ) : (
          <span>Place Order</span>
        )}
      </button>
    </>
  );
};

export default ChooseDelivery;
