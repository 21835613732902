import React, { useEffect, useState } from "react";
import Slider from "./Slider";
import "./slider.css";
import { category_slider, home_slider } from "../../../../../../api/api";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const AllOverReview = () => {
  const [sliderData, setSliderData] = useState(null);
  useEffect(() => {
    const loadSliderData = async () => {
      try {
        const response = await home_slider();
        setSliderData(response?.data?.data);
      } catch (error) {
        console.log("error", error);
      }
    };
    loadSliderData();
  }, []);

  return (
    <section
      class="why-choose-bunches"
      aria-label="Why Choose Integrity Flowers"
    >
      <div class="content background-teal">
        <div class="slider">
          {sliderData && (
            <Slider height="98px" auto speed={3000}>
              {sliderData.map((slider) => {
                return slider?.image !== null ? (
                  <div
                    className="slider-item-infikey"
                    style={{ width: "100%", background: "pink" }}
                  >
                    <div class="review" style={{ textAlign: "center" }}>
                      <img
                        alt="4.7 Star Reviews"
                        src={imageUrl + slider?.image}
                        height="20"
                      />
                      <span>
                        <p>{slider?.heading_1}</p>
                        <p>{slider?.heading_2}</p>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div
                    className="slider-item-infikey"
                    style={{
                      width: "100%",
                      background: "pink",
                      textAlign: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <p>{slider?.heading_1}</p>
                      <p>{slider?.heading_2}</p>
                    </div>
                  </div>
                );
              })}

              {/* <div
              className="slider-item-infikey"
              style={{ width: "100%", background: "pink" }}
            >
              <div style={{ textAlign: "center" }}>
                <p>10% OF PROFITS TO CHARITY</p>
                <p>Your gift keeps on giving</p>
              </div>
            </div> */}
              {/* <div
              className="slider-item-infikey"
              style={{
                width: "100%",
                background: "pink",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <p>7 DAY FRESHNESS GUARANTEE</p>
                <p>Sent in bud for a longer vase life</p>
              </div>
            </div> */}
              {/* <div
              className="slider-item-infikey"
              style={{ width: "100%", background: "pink" }}
            >
              <div class="review" style={{ textAlign: "center" }}>
                <img
                  alt="4.7 Star Reviews"
                  src="https://media.bunches.co.uk/icons/reviews-banner-element-stars.svg"
                  height="20"
                />
                <span>
                  from over <b>180k</b> reviews
                </span>
              </div>
            </div> */}
            </Slider>
          )}
        </div>
      </div>
    </section>
  );
};

export default AllOverReview;
