import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleBasket } from "../../features/menuBar/menubarSlice";
import {
  AllCartItems,
  RemoveMultipleItemsToBasket,
  checkCoupon,
  storeMultipleItemsToBasket,
  vat_tax,
} from "../../api/api";
import BasketItem from "./BasketItem";
import { addBasket } from "../../features/basket/basket";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  addCoupon,
  addCouponResponse,
} from "../../features/checkout/CouponSlice";

const Basket = () => {
  const user = useSelector((state) => state.user);
  const basket = useSelector((state) => state.menubar.basket);
  const basketItems = useSelector((state) => state?.basket?.basket);
  const basketCount = useSelector((state) => state?.basket?.basketCount);
  const couponState = useSelector((state) => state.coupon);
  // addCouponResponse
  const isCheckout = useSelector((state) => state?.checkout?.isCheckout);
  const dispatch = useDispatch();

  const [vat, setVat] = useState(null);

  const [couponResponse, setCouponResponse] = useState(null);

  const [coupon, setCoupon] = useState("");
  const [couponError, setCouponError] = useState("");

  const handleBasketClick = () => {
    dispatch(toggleBasket());
  };

  const [allCartItems, setAllCartItems] = useState(null);

  useEffect(() => {
    if (couponState.couponVerification !== "") {
      setCouponError("");
    }
  }, [couponState]);

  useEffect(() => {
    if (allCartItems !== null && allCartItems?.length === 0) {
      setCoupon("");
    }
  }, [allCartItems]);

  useEffect(() => {
    loadCartItem();
  }, [basketCount]);

  useEffect(() => {
    setAllCartItems(basketItems);
  }, [basketItems]);

  useEffect(() => {
    loadCartItem();
  }, [user]);

  useEffect(() => {
    loadCartItem();
    loadVatTax();
  }, []);

  const loadVatTax = async () => {
    try {
      const response = await vat_tax();
      setVat(response?.data?.data?.tax_amount);
    } catch (error) {
      console.log("error", error);
    }
  };

  const loadCartItem = async () => {
    try {
      const response = await AllCartItems();
      const userCartLiveData = response?.data?.data;
      setAllCartItems(userCartLiveData);
      dispatch(addBasket(userCartLiveData));
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCouponChange = (e) => {
    setCouponError("");
    setCouponResponse(null);
    setCoupon(e.target.value);
  };
  const handleCouponApplyClick = async () => {
    if (coupon == "") {
      setCouponError("Please Enter Coupon Code");
      dispatch(addCouponResponse(""));
      return;
    }
    try {
      let cartTotalPrise;
      if (Number(vat) !== 0) {
        cartTotalPrise =
          allCartItems[0]?.item_sale_price !== null
            ? allCartItems[0]?.item_sale_price +
              allCartItems[0]?.item_sale_price / vat
            : allCartItems[0]?.item_price + allCartItems[0]?.item_price / vat;
      } else {
        cartTotalPrise =
          allCartItems[0]?.item_sale_price !== null
            ? allCartItems[0]?.item_sale_price
            : allCartItems[0]?.item_price;
      }
      const response = await checkCoupon({
        cart_total: cartTotalPrise,
        coupon_code: coupon,
      });
      if (response?.data?.message === "Success") {
        setCoupon("");
        dispatch(addCoupon(coupon));
        dispatch(addCouponResponse(response?.data));
        setCouponResponse(response?.data);
      }
    } catch (error) {
      setCouponError(error?.response?.data?.message);
      dispatch(addCoupon(""));
      dispatch(addCouponResponse(""));
      setCouponResponse(null);
      console.log("error", error);
    }
  };
  console.log("allCartItems", allCartItems);
  return (
    <div
      class={`hJxY4KAaikA6k1uxMJgp ${basket ? "KCp0In0VVjaSoevu8EBL" : ""}`}
      aria-label="Basket"
      aria-hidden={!basket}
    >
      <div class="iZqcPQ9K60hcfCCVdsTw">
        <div class="DTkrinpxQVB0luxr7f7J">
          <h1>Basket</h1>
          <svg
            onClick={handleBasketClick}
            width="15"
            height="15"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="YtILa781TnisAIdVkgfu"
            role="button"
            aria-label="Close Basket"
            tabindex="0"
          >
            <title>Close Basket</title>
            <path
              d="M18 1L2 17"
              stroke="#78858D"
              strokeWidth="2"
              strokeLinecap="round"
            ></path>
            <path
              d="M2 1L18 17"
              stroke="#78858D"
              strokeWidth="2"
              strokeLinecap="round"
            ></path>
          </svg>
        </div>
        <div class="IcTaPTIS_a6gF0QNn394">
          <div class="iM5cCU9QOPnx87hf4GHA">
            <section class="h2CJwqXTbGiqnOOs4v3w">
              <label for="discountCode">
                <h2 style={{ fontFamily: "Overlock", fontWeight: "bold" }}>
                  Promo/voucher code
                </h2>
              </label>
              <div class="VnBpn0GY4tQEXz8hKHDQ LtGgg0G5_kft3k6Rogcm">
                <input
                  id="discountCode"
                  type="text"
                  placeholder="Enter your code"
                  value={coupon}
                  onChange={handleCouponChange}
                />
                <button
                  onClick={handleCouponApplyClick}
                  class="VRZl5T_cEakrM5LuPOBb"
                  type="button"
                  disabled=""
                >
                  Apply
                </button>
              </div>
              {couponError !== "" && (
                <div style={{ color: "red" }}>{couponError}</div>
              )}
              {couponState?.couponVerification !== "" && (
                <div style={{ color: "green" }}>
                  Coupon code '{couponState.coupon}' applied. Discount: £{" "}
                  {Number(couponState.couponVerification?.discount).toFixed(2)}.
                  Enjoy your savings!
                </div>
              )}
            </section>
          </div>
        </div>
        <div class="vvMrS5GcfuPIpgxz8IYf">
          {allCartItems &&
            allCartItems.map((cartItem) => {
              return <BasketItem cartItem={cartItem} />;
            })}
        </div>
        {/* <Link class="E02GN3vPiDwi03VcE_Cc" to="/checkout/1">
          <div class="gIimbRmEVNvv55dGQ4bs" role="alert">
            You've not supplied your name and email address,{" "}
            <span>click here</span> to fill them in.
          </div>
        </Link> */}
        {allCartItems && allCartItems.length > 0 && !isCheckout && (
          <div class="AFtaTeoIDA88nEKvlw8i">
            <Link to="/checkout/1">
              <button class="VRZl5T_cEakrM5LuPOBb YLxdcrrIEpFGSqqBDTXs">
                Checkout
              </button>
            </Link>
          </div>
        )}

        <div class="KLeYQcgfS0xpZ8qHwv9N"></div>
      </div>
    </div>
  );
};

export default Basket;
