import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { addCategoryName } from "../../../../../features/Category/CategorySlice";

const SubCategory = ({ category, i }) => {
  const dispatch = useDispatch();
  const imageUrl = process.env.REACT_APP_IMAGE_URL;

  const handleClick = (category) => {
    dispatch(addCategoryName(category?.category_name));
  };
  return (
    <li key={i}>
      <Link
        onClick={() => handleClick(category)}
        to={`/sub-category/flowers/${category?.id}`}
      >
        <img
          class="img-fluid"
          src={imageUrl + category?.category_image}
          alt="Letterbox Flowers"
        />
        <div class="content">
          <p style={{ fontFamily: "Overlock", fontWeight: "bold" }}>
            {category?.category_name}
          </p>
        </div>
      </Link>
    </li>
  );
};

export default SubCategory;
